import React, { useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import SystemLayout from './shared/layouts/SystemLayout';
import { LayoutsE } from './shared/routes';
import AdminLayout from './shared/layouts/AdminLayout';
import AuthLayout from './shared/layouts/AuthLayout';
import { useAuthContext } from 'shared/contexts/AuthContext';
import { useColorMode } from '@chakra-ui/system';
import useGetRoutes from 'shared/hooks/useGetRoutes';
import i18next from 'i18next';

const App = () => {
  const { self } = useAuthContext();
  const { colorMode, toggleColorMode } = useColorMode();

  {/* TODO Dark mode */ }
  useEffect(() => {
    if (colorMode === 'dark') toggleColorMode()
  }, [])

  useEffect(() => {
    const i18nextLng = localStorage.getItem("i18nextLng");
    if (i18nextLng) return;
    i18next.changeLanguage('en');
  }, [])

  const authRoutes = useGetRoutes(LayoutsE.AUTH);
  const dashboardRoutes = useGetRoutes(LayoutsE.DASHBOARD);
  const systemRoutes = useGetRoutes(LayoutsE.SYSTEM);

  if (!self) {
    return (
      <Routes>
        <Route path={LayoutsE.AUTH} element={<AuthLayout />}>
          {authRoutes.routes.map((route, index) => (
            <Route
              path={route.path}
              element={<route.component />}
              key={index}
            />
          ))}
          <Route path={`/${LayoutsE.AUTH}`} element={<Navigate to={authRoutes.redirectUrl} />} />
        </Route>
        <Route path="*" element={<Navigate to={authRoutes.redirectUrl} />} />
      </Routes>
    )
  };

  return (
    <Routes>
      <Route path={LayoutsE.DASHBOARD} element={<AdminLayout />} >
        {dashboardRoutes.routes.map((route, index) => (
          <Route
            path={route.path}
            element={<route.component />}
            key={index}
          />
        ))}
        <Route path={`/${LayoutsE.DASHBOARD}`} element={<Navigate to={dashboardRoutes.redirectUrl} />} />
      </Route>
      <Route path={LayoutsE.SYSTEM} element={<SystemLayout />} >
        {systemRoutes.routes.map((route, index) => (
          <Route
            path={route.path}
            element={<route.component />}
            key={index}
          />
        ))}
        <Route path={`/${LayoutsE.SYSTEM}`} element={<Navigate to={systemRoutes.redirectUrl} />} />
      </Route>
      <Route path="*" element={<Navigate to={dashboardRoutes.redirectUrl} />} />
    </Routes>
  );
};

export default App;
