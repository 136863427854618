import {Button, useDisclosure} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import TextField from 'shared/components/form/TextField';
import useGetColors from 'shared/hooks/useGetColors';
import { fillFormErrors } from 'shared/utils/devUtils';
import { useTranslation } from 'react-i18next';
import SelectField from '../../components/form/SelectField'
import Field from '../../components/form/Field';
import { useGetChannels } from '../../api/requests/channels';
import {
  getSignInQrCodeValues,
  signInQrCodeFormResolver,
  SignInQrCodeFormT,
  sourceTypeOptions
} from '../../form-values/signInQrCode';
import { useSignInQrCode } from '../../api/requests/managers';
import PageTitle from '../admin/PageTitle';
import Card from '../../components/horizon-ui/card/Card';
import { useGetSignInBreadcrumbs } from './constants';
import { io } from 'socket.io-client';
import { BASE_URL } from 'shared/utils/constants';
import {PathsE} from '../../routes'
import { SourceType } from 'shared/api/types/channels';
import { ResourceTypeT } from 'shared/api/types/subscribers';
import { useGetBots } from 'shared/api/requests/bots';
import { QRData } from 'shared/api/types/managers';
import QrModal from './QrModal';

const SignInQrCode = () => {
  const [qrData, setQrData] = useState(null);
  const [timeLeft, setTimeLeft] = useState(0);
  const [attempts, setAttempts] = useState(0);
  const [sourceType, setSourceType] = useState<ResourceTypeT>('channel');

  const { t } = useTranslation();
  const { mutate, isPending } = useSignInQrCode();
  const { white, brand500, brand400  } = useGetColors();
  const methods = useForm<SignInQrCodeFormT>({
    defaultValues: getSignInQrCodeValues(),
    resolver: signInQrCodeFormResolver,
    mode: "onChange"
  });
  const { formState: { isValid }, trigger } = methods;
  const { isOpen, onOpen, onClose } = useDisclosure();

  const breadcrumbs = useGetSignInBreadcrumbs();

  useEffect(() => {
    trigger();

    const socket = io(BASE_URL);

    socket.on('open', () => {
      console.log('WebSocket connection is opened');
    });

    socket.on('sign-in-qr-code', (event: QRData) => {
      setQrData(event);
      setAttempts(event.showedQr);
      const currentTime = Math.floor(Date.now() / 1000);
      const timeRemaining = event.expires - currentTime;
      setTimeLeft(timeRemaining);
      onOpen();
    });

    socket.on('close', () => {
      console.log('WebSocket connection closed');
    });

    socket.on('error', (error: FixMeLater) => {
      console.error('WebSocket error:', error);
    });

    return () => {
      socket.close();
    };
  }, []);

  useEffect(() => {
    let timerInterval: ReturnType<typeof setInterval>;

    if (timeLeft > 0) {
      timerInterval = setInterval(() => {
        setTimeLeft((prevTimeLeft) => {
          if (prevTimeLeft <= 1) {
            clearInterval(timerInterval);
            return 0;
          } else {
            return prevTimeLeft - 1;
          }
        });
      }, 1000);
    }

    return () => {
      if (timerInterval) {
        clearInterval(timerInterval);
      }
    };
  }, [timeLeft]);

  const onSubmit: SubmitHandler<SignInQrCodeFormT> = (values) => mutate(values, { onError: (error) => fillFormErrors(methods.setError, error) });

  const fieldProps = {
    mb: '20px',
    w: {
      base: '100%',
      md: '50%',
    },
  };

  return (
    <>
      <PageTitle
        title={t('managers.signInQrCode')}
        breadcrumbs={breadcrumbs}
        backUrl={{
          path: PathsE.MANAGERS,
        }}
      />
      <Card mb="20px" borderRadius="20px" px="30px">
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <SelectField<SignInQrCodeFormT>
              options={sourceTypeOptions}
              name="sourceType"
              defaultValue="channels"
              label={`${t('forms.source')} *`}
              placeholder={t('forms.source')}
              onChange={(sourceType: SourceType) => setSourceType(sourceType)}
              {...fieldProps}
            />
            {sourceType === 'bot' ? (
              <Field
                type="async-select"
                name="sourceId"
                label={`${t('forms.sourceId')} *`}
                placeholder={t('forms.selectSourceId')}
                optionsQuery={useGetBots}
                labelName="name"
                valueName="id"
                defaultValue={null}
                {...fieldProps}
              />
            ) : (
              <Field
                type="async-select"
                name="sourceId"
                label={`${t('forms.sourceId')} *`}
                placeholder={t('forms.selectSourceId')}
                optionsQuery={useGetChannels}
                labelName="name"
                valueName="id"
                defaultValue={null}
                {...fieldProps}
              />
            )}

            <TextField<SignInQrCodeFormT>
              name="apiId"
              label={`${t('forms.apiId')} *`}
              defaultValue={6}
              placeholder={t('forms.enterApiId')}
              style={{marginTop: '20px'}}
              {...fieldProps}
            />
            <TextField<SignInQrCodeFormT>
              name="apiHash"
              label={`${t('forms.apiHash')} *`}
              placeholder={t('forms.enterApiHash')}
              defaultValue="eb06d4abfb49dc3eeb1aeb98ae0f581e"
              {...fieldProps}
            />
            <Button
              color={white}
              borderRadius="8px"
              _hover={{
                bgColor: brand400,
              }}
              bgColor={brand500}
              isLoading={isPending}
              w="100px"
              type="submit"
              isDisabled={!isValid}
            >
              {t('buttons.next')}
            </Button>
          </form>
        </FormProvider>
      </Card>
      <QrModal isOpen={isOpen} onClose={onClose} qrData={qrData} attempts={attempts} timeLeft={timeLeft}/>
    </>
  );
};

export default SignInQrCode
