import React, { ReactNode } from 'react';
import { Link as RouterLink, NavLinkProps } from 'react-router-dom';
import useGenerateUrl from 'shared/hooks/useGenerateUrl';
import { LayoutsE, PathsE } from 'shared/routes';

type Props<PathParams extends Record<string, string> = undefined> = Modify<NavLinkProps & React.RefAttributes<HTMLAnchorElement> ,{
  layout?: LayoutsE,
  path: PathsE,
  to?: string,
  pathsParams?: PathParams extends undefined ? undefined : PathParams;
  children: ReactNode;
}>

const Link = <PathParams extends Record<string, string> = undefined>({ children, path, layout = LayoutsE.DASHBOARD, pathsParams }: Props<PathParams>) => {
  const to = useGenerateUrl(path, layout, pathsParams);
  return (
    <RouterLink to={to}>{children}</RouterLink>
  )
};

export default Link;
