import { ToastId } from '@chakra-ui/react';
import { AxiosError, HttpStatusCode } from 'axios';
import { FieldPath, UseFormSetError } from 'react-hook-form';
import { FilterValue } from 'shared/types/filters';
import Cookies from 'universal-cookie';
import { ErrorsResponse } from '../api/types/typedQueries';
import { LayoutsE } from '../routes';
import { AUTHORIZATION_COOKIE } from './constants';
import { formatTimestampToBackFormat } from './formattings';

export const defaultErrorHandler = (
  errorToast: (message?: string) => ToastId,
) => (error: unknown) => {
  const axiosError = (error as AxiosError<ErrorsResponse>)?.response;

  if (axiosError) {
    switch (axiosError.status) {
      case HttpStatusCode.Unauthorized:
        if (window.location.pathname === '/' || window.location.pathname.includes(LayoutsE.AUTH)) return;

        errorToast(axiosError.statusText);
        window.location.pathname = LayoutsE.AUTH
        break;

      case HttpStatusCode.UnprocessableEntity:
        break;

      case HttpStatusCode.BadRequest:
        errorToast(axiosError.data.error?.message);
        if (axiosError.data.error?.data) return axiosError;
        break;

      default:
        if (axiosError.data.error?.data?.message) {
          errorToast(axiosError.data.error?.data?.message.toString());
          break;
        }
        if (axiosError.data.error?.message) {
          errorToast(axiosError.data.error?.message);
          break;
        }
        break;
    }
  }
  return error;
};

type CookiesValue = FixMeLater;

export const getToken = (): string => {
  const cookies = new Cookies();
  const token = cookies.get(AUTHORIZATION_COOKIE);
  return token || '';
};

export const getCookies = (key: string): CookiesValue => {
  const cookies = new Cookies();
  return cookies.get(key);
};

export const setCookies = (key: string, value: CookiesValue) => {
  const cookies = new Cookies();
  cookies.set(key, value, { path: '/' });
};

export const removeCookies = (key: string) => {
  const cookies = new Cookies();
  cookies.remove(key, { path: '/' });
};

export const fillFormErrors = <FormT extends UnknownRecord>(
  setError: UseFormSetError<FormT>,
  axiosError: AxiosError<ErrorsResponse>,
  formValues?: FormT,
) => {
  if (!axiosError.response.data.error?.data) return;
  Object.keys(axiosError.response.data.error.data).forEach(key => {
    if (key in formValues) {
      setError(key as FieldPath<unknown>, { type: 'custom', message: axiosError.response.data.error.message })
    }
  })
}

export const addExpirationTimeToCurrTime = (expirationTime: number) => (
  new Date().getTime() + expirationTime * 1000
)

export const getInputStyles = (small: boolean) => ({
  borderRadius: small ? "4px" : "8px",
  height: small ? "24px" : "40px",
  fontWeight: "500",
  py: "6px",
  pl: small ? "8px" : "16px",
  fontSize: "sm",
  ms: { base: '0px', md: '0px' },
});

export const DATE_KEYS = ["createdAt", "messageAt"];

const formatDateArray = (key: string, value: number[]) => {
  const result = {} as UnknownRecord;
  if (value[0]) {
    result[`${key}Start`] = formatTimestampToBackFormat(value[0]);
  }
  if (value[1]) {
    result[`${key}End`] = formatTimestampToBackFormat(value[1]);
  }
  return result;
}

export const formatObjectWithDateAsStringsToNumbers = (obj: UnknownRecord) => (
  Object.entries(obj).reduce((prev, [key, value]) => {
    if (DATE_KEYS.includes(key)) {
      const arrayWithStrings = value as [string, string];
      return { ...prev, [key]: [Number(arrayWithStrings[0]), Number(arrayWithStrings[1])] }
    }

    return { ...prev, [key]: value }
  }, {})
)

const formatIdsArray = (key: string, value: (number | string)[]) => {
  const result = {} as UnknownRecord;
  result[key] = value.join(",");
  return result;
}

export const formatFilterParams = (data: FilterValue) => {
  const fullyObject: UnknownRecord = {};
  if (data === null || data === undefined) return data;

  Object.entries(data).forEach(([key, value]) => {
    if (Array.isArray(value) && DATE_KEYS.includes(key)) {
      Object.assign(fullyObject, formatDateArray(key, value));
    } else if (Array.isArray(value) && key.includes('Ids')) {
      Object.assign(fullyObject, formatIdsArray(key, value));
    } else if (value instanceof File) {
      fullyObject[key] = value;
    } else if (typeof value === "object" && value !== null) {
      const filteredSubObject = formatFilterParams(value as FilterValue);
      if (filteredSubObject && Object.keys(filteredSubObject).length) {
        Object.assign(fullyObject, filteredSubObject);
      }
    } else if (!value && typeof value !== "boolean") {
      fullyObject[key] = null;
    } else if (value || value === false) {
      fullyObject[key] = value;
    }
  });

  return fullyObject;
};
