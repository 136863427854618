import { ChevronDownIcon } from '@chakra-ui/icons';
import { Button, Flex, Grid, GridItem, Menu, MenuButton, MenuItem, MenuList, Text } from '@chakra-ui/react';
import MatIcon from 'components/MatIcon';
import React, { useMemo } from 'react';
import useGetColors from '../hooks/useGetColors';
import { useTranslation } from 'react-i18next';

interface Props {
  page: number;
  size: number;
  total: number;
  setSize: (v: number) => void;
  setPage: (v: number) => void;
}

const Pagination = ({ page, size, total, setSize, setPage }: Props) => {
  const {
    gray100,
    gray50,
    gray800,
    disabledSecondaryButtonText,
    textColorBrand,
    white,
    gray500,
    blue500,
  } = useGetColors();
  const { t } = useTranslation();
  const isDisableBack = page === 0;
  const isDisableForward = useMemo(
    () => (total - (page + 1) * size) <= 0, [
    total, page, size],
  );

  const sizeOptions = [20, 50, 100];

  const sizeOptionsToRender = useMemo(() => {
    // const filteredOptions = sizeOptions.filter(size => size < total);
    // if (filteredOptions.length) return filteredOptions;
    // return [sizeOptions[0]];
    return sizeOptions;
  }, [sizeOptions, total])

  const btnStyle = {
    height: '40px',
    width: '40px',
    borderRadius: '50%',
  };

  const handleChangePage = (page: number) => {
    if (page >= 0) setPage(page);
  };
  const handleChangeSize = (size: number) => {
    if (size) setSize(size);
  };

  const pages = useMemo(
    () => {
      const lastPage = Math.ceil(total / size);
      const pages = Array.from({ length: lastPage }, (_, i) => i + 1);
      return pages;
    },
    [total, size]
  );

  const viewPages = useMemo(() => {
    if (!pages.length) return [];
    if (pages.length <= 7) return pages.map((p) => p.toString());

    const countToShowEllipsis = 4;
    const ellipsis = "...";
    const currPage = page + 1;
    const lastPage = pages[pages.length - 1];
    const showEllipsesLeft = currPage - pages[0] >= countToShowEllipsis;
    const showEllipsesRight = lastPage - currPage >= countToShowEllipsis;
    const result = ["1"];

    const numbersToStrings = (numbers: number[]) => numbers.map((item) => item.toString());

    if (showEllipsesLeft) result.push(ellipsis);
    else result.push(...numbersToStrings(pages.slice(1, 5)))

    if (showEllipsesLeft && showEllipsesRight) {
      result.push(...numbersToStrings(pages.slice(currPage - 2, currPage + 1)));
    }

    if (showEllipsesRight) result.push(ellipsis);
    else result.push(...numbersToStrings(pages.slice(-5, -1)))

    result.push(lastPage.toString());

    return result;
  }, [pages, total, size, page]);

  const toCountOfRows = useMemo(() => {
    const curFirstItem = size * page + size;
    return curFirstItem < total ? curFirstItem : total;
  }, [size, page, total])
  const currCountOfRows = useMemo(() => size * page + 1, [size, page])

  return (
    <Grid mt="12px"
      w="100%"
      justifyContent="space-between"
      gridTemplate=""
      // flexDirection={{
      //   sm: 'column',
      //   "md": 'row',
      // }}
      templateColumns={{
        sm: '1fr',
        md: '1fr 1fr',
        lg: '1fr 1fr 1fr'
      }}
    >
      <GridItem display={{
        sm: 'none',
        lg: 'block'
      }} />
      <GridItem marginX={{
        md: "none",
        lg: "auto",
      }}>
        <Grid gridGap="10px" gridAutoFlow="column" w="fit-content">
          {!isDisableBack && (
            <Button
              {...btnStyle}
              bgColor={isDisableBack ? gray50 : blue500}
              onClick={() => handleChangePage(page - 1)}
              isDisabled={isDisableBack}
              _hover={{
                backgroundColor: textColorBrand,
              }}
            >
              <MatIcon size={24} name="keyboard_arrow_left" variant="rounded" color={
                isDisableBack ? disabledSecondaryButtonText : white
              } />
            </Button>
          )}
          {viewPages.map((p, index) => (
            <Button
              {...btnStyle}
              key={`${p}${index}`}
              isDisabled={isNaN(Number(p))}
              bgColor={page === Number(p) - 1 ? blue500 : gray100}
              _hover={{
                backgroundColor: textColorBrand,
                color: white
              }}
              onClick={() => handleChangePage(Number(p) - 1)}
              color={page === Number(p) - 1 ? white : gray800}
            >
              <Text
              >
                {p}
              </Text>
            </Button>
          ))}
          {!isDisableForward && (
            <Button
              {...btnStyle}
              bgColor={isDisableForward ? gray50 : blue500}
              onClick={() => handleChangePage(page + 1)}
              isDisabled={isDisableForward}
              _hover={{
                backgroundColor: textColorBrand,
              }}
            >
              <MatIcon size={24} name="keyboard_arrow_right" variant="rounded" color={
                isDisableForward
                  ? disabledSecondaryButtonText
                  : white
              } />
            </Button>
          )}
        </Grid>
      </GridItem>
      <GridItem marginLeft="auto">
        <Flex alignItems="center" mt={{
          sm: '10px',
          "4sm": '0'
        }}>
          <Text mr="20px" fontSize="14px" color={gray500}>
            {t('general.table.pagination', { currCountOfRows, toCountOfRows, total })}
          </Text>
          <Menu>
            <MenuButton
              px={4}
              py={2}
              transition='all 0.2s'
              borderRadius="8px"
              borderWidth='1px'
              whiteSpace='nowrap'
            >
              {size} <ChevronDownIcon />
            </MenuButton>
            <MenuList minW="70px">
              {sizeOptionsToRender.map((option) => (
                <MenuItem
                  key={option}
                  onClick={() => handleChangeSize(option)}
                  _hover={{
                    backgroundColor: gray100,
                  }}
                >{option}</MenuItem>
              ))}
            </MenuList>
          </Menu>
        </Flex>
      </GridItem>
    </Grid>
  );
};

export default Pagination;
