import {
  Box,
  Flex,
  Text,
} from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useGetOneChannel, useGetTemplateMatches } from 'shared/api/requests/channels'
import Card from 'shared/components/horizon-ui/card/Card';
import Loader from 'shared/components/Loader';
import MainModelInfo from 'shared/components/MainModelInfo';
import {
  CreateChannelFormT,
  createChannelResolver,
  getCreateChannelValues
} from 'shared/form-values/channels';
import useGetColors from 'shared/hooks/useGetColors';
import { PathsE, PathsParams } from 'shared/routes'
import PageTitle from '../admin/PageTitle';
import { HSeparator } from 'shared/components/horizon-ui/separator/Separator';

const ViewChannel = () => {
  const { t } = useTranslation();
  const { channelId } = useParams<PathsParams[PathsE.SHOW_CHANNEL]>();
  const { gray700 } = useGetColors();
  const requestParams = {
    pathParams: { channelId },
    params: {},
  };

  const { data, isLoading } = useGetOneChannel(requestParams);
  const { data: botAnswerData } = useGetTemplateMatches(requestParams);
  const methods = useForm<CreateChannelFormT>({
    defaultValues: getCreateChannelValues(),
    resolver: createChannelResolver,
  });

  const renderName = () => `${data.name ? data.name + ' ' : ''}`;

  const breadcrumbs = [
    {
      path: PathsE.HOME,
      text: t('general.home'),
    },
    {
      path: PathsE.CHANNELS,
      text: t('managers.managers'),
    },
  ];

  if (isLoading || !data || !botAnswerData) return <Loader />;

  return (
    <Box>
      <PageTitle
        title={`${data.name ? data.name + ' ' : ''}`}
        breadcrumbs={breadcrumbs}
        backUrl={{
          path: PathsE.MANAGERS,
        }}
      />
      <Card mb="20px" borderRadius="20px" px="30px">
        <Flex justifyContent="space-between" alignItems="center" mt="-10px">
          <Text color={gray700} fontWeight="600">
            {renderName()}
          </Text>
        </Flex>
        <FormProvider {...methods}>
          <MainModelInfo data={data} />
          <HSeparator my="20px" mx="-20px" width="calc(100% + 40px)" />
        </FormProvider>
      </Card>
    </Box>
  );
};

export default ViewChannel;
