import {
  Center,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';
import {
  ErrorMessage,
  FieldValuesFromFieldErrors
} from '@hookform/error-message';
import MatIcon from 'components/MatIcon';
import { forwardRef, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import {
  FieldErrors,
  FieldName,
  Path,
  PathValue,
  useFormContext
} from 'react-hook-form';
import { FieldProps } from 'shared/types/filters';

import { formatDateToTimestamp, formatTimestampToDate } from 'shared/utils/formattings';

type DateValueT = Date | null;

const RangeDateField = <F extends UnknownRecord>({
  label,
  placeholder,
  name,
  showTime = false,
  size,
  ...props
}: FieldProps<'date-range'>) => {
  const {
    register,
    setValue,
    formState: { errors, touchedFields },
    watch,
  } = useFormContext<F>();
  const value = watch(name) as number[];
  const [selectedDates, setSelectedDates] = useState<[DateValueT, DateValueT]>([null, null]);

  const isInvalid = !!errors[name] && !!touchedFields[name];

  useEffect(() => {
    if (!selectedDates[1]) return;
    setValue(name, [formatDateToTimestamp(selectedDates[0]), formatDateToTimestamp(selectedDates[1])] as PathValue<F, Path<F>>);
  }, [selectedDates])

  const isValuePresent = selectedDates.length > 0 && !!selectedDates[0] && !!selectedDates[1];

  useEffect(() => {
    if (isValuePresent) return;
    setSelectedDates([formatTimestampToDate(value[0]), formatTimestampToDate(value[1])]);
  }, [value])

  const customDateInput = ({ value, onClick, onChange }: FixMeLater, ref: FixMeLater) => (
    <Input
      size={size}
      autoComplete="off"
      background="white"
      value={value}
      ref={ref}
      onClick={onClick}
      onChange={onChange}
      placeholder={placeholder}
    />
  );
  customDateInput.displayName = "DateInput";
  const icon = <MatIcon style={{ height: '100%' }} name="calendar_month" />;

  const CustomInput = forwardRef(customDateInput);


  return (
    <FormControl {...props} size={size} position="relative" isInvalid={isInvalid}>
      {label && (
        <FormLabel fontSize="md" fontWeight="500" mb="8px">
          {label}
        </FormLabel>
      )}
      <InputGroup>
        <DatePicker<true>
          {...register(name)}
          onChange={(value) => setSelectedDates(value)}
          isClearable={isValuePresent}
          startDate={selectedDates[0]}
          endDate={selectedDates[1]}
          className="react-datapicker__input-text"
          showTimeSelect={showTime}
          dateFormat={showTime ? "dd/MM/yyyy HH:mm:ss" : "dd/MM/yyyy"}
          timeFormat="HH:mm"
          calendarStartDay={1}
          customInput={<CustomInput />}
          selectsRange
        />
        {!isValuePresent &&
          <InputRightElement height="100%" color="gray.500">
            <Center alignItems="center">
              {icon}
            </Center>
          </InputRightElement>
        }
      </InputGroup>
      {isInvalid && (
        <FormErrorMessage>
          <ErrorMessage
            errors={errors}
            name={
              (name as unknown) as FieldName<
                FieldValuesFromFieldErrors<FieldErrors<F>>
              >
            }
          />
        </FormErrorMessage>
      )}
    </FormControl>
  );
};

export default RangeDateField;


