import {
  Box,
  Flex,
  Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Card from 'shared/components/horizon-ui/card/Card';
import Loader from 'shared/components/Loader';
import MainModelInfo from 'shared/components/MainModelInfo';
import useGetColors from 'shared/hooks/useGetColors';
import { PathsE, PathsParams } from 'shared/routes'
import PageTitle from '../admin/PageTitle';
import { useGetOneSubscription } from 'shared/api/requests/subscriptions';

const Show = () => {
  const { t } = useTranslation();
  const { subscriptionId } = useParams<PathsParams[PathsE.SHOW_SUBSCRIPTION]>();
  
  const { gray700 } = useGetColors();
  const requestParams = {
    pathParams: { subscriptionId },
    params: {},
  };

  const { data, isLoading } = useGetOneSubscription(requestParams);

  const renderName = () => `${data.name ? data.name + ' ' : ''}`;

  const breadcrumbs = [
    {
      path: PathsE.HOME,
      text: t('general.home'),
    },
    {
      path: PathsE.SUBSCRIPTIONS,
      text: t('subscriptions.subscriptions'),
    },
  ];

  if (isLoading || !data) return <Loader />;

  return (
    <>
      <Box>
        <PageTitle
          title={`${data.name ? data.name + ' ' : ''}`}
          breadcrumbs={breadcrumbs}
          backUrl={{
            path: PathsE.SUBSCRIPTIONS,
          }}
        />
        <Card mb="20px" borderRadius="20px" px="30px">
          <Flex justifyContent="space-between" alignItems="center" mt="-10px">
            <Text color={gray700} fontWeight="600">
              {renderName()}
            </Text>
          </Flex>
          <MainModelInfo data={data} />
        </Card>
      </Box>
      {/* <DeleteModal
        title={t('subscriptions.deleteSubscription')}
        text={t('general.approveTo', { phrase: t('subscriptions.deleteSubscription') })}
        onClose={onClose}
        isOpen={isOpen}
        onSubmit={() => deleteMutate({})}
      /> */}
    </>
  );
};

export default Show;
