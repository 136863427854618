import React from 'react';
import { Menu, MenuList, MenuItem, Button, Text, Tooltip } from '@chakra-ui/react'
import MatIcon from 'components/MatIcon';
import { PathsE } from 'shared/routes';
import useGetColors from 'shared/hooks/useGetColors';
import Link from '../../components/Link';
import { useTranslation } from 'react-i18next';
import CustomMenuButton from '../../components/CustomMenuButton'

const AddManagerMenu = () => {
  const { t } = useTranslation();
  const { gray100, gray700 } = useGetColors();

  return (
    <Menu>
      <CustomMenuButton
        as={Button}
        fontSize="16px"
        ml="20px"
        color="white"
        backgroundColor="brand.500"
        fontWeight="600"
        _hover={{
          backgroundColor: "brand.400",
        }}
        paddingY="8px"
        display="flex"
        borderRadius="8px"
        alignItems="center"
      >
        <MatIcon name="add" size={24} color="gray800" variant="rounded" />
        <Text ml="8px">{t('managers.manager')}</Text>
      </CustomMenuButton>
      <MenuList>
        <Link path={PathsE.SIGNIN_QR_CODE}>
          <MenuItem _hover={{ backgroundColor: gray100 }} justifyContent="space-between" gap={4}>
            <Text color={gray700} fontSize="sm">SignIn - QR Code</Text>
            <Tooltip label="Sign in with QR code info will be here" aria-label="Sign In QR Code Info" hasArrow placement="right">
              <span>
                <MatIcon name="info" />
              </span>
            </Tooltip>
          </MenuItem>
        </Link>
        <Link path={PathsE.SIGNIN_SEND_CODE}>
          <MenuItem _hover={{ backgroundColor: gray100 }} justifyContent="space-between" gap={4}>
            <Text color={gray700} fontSize="sm">SignIn - Send Code</Text>
            <Tooltip label="Sign in with Send Code info will be here" aria-label="Sign In QR Code Info" hasArrow placement="right">
              <span>
                <MatIcon name="info" />
              </span>
            </Tooltip>
          </MenuItem>
        </Link>
        <Link path={PathsE.SIGNIN_SEND_CODE_PASSWORD}>
          <MenuItem _hover={{ backgroundColor: gray100 }} justifyContent="space-between" gap={4}>
            <Text color={gray700} fontSize="sm">SignIn - Send Code & Password</Text>
            <Tooltip label="Sign in with Send Code & Password info will be here" aria-label="Sign In QR Code Info" hasArrow placement="right">
              <span>
                <MatIcon name="info" />
              </span>
            </Tooltip>
          </MenuItem>
        </Link>
      </MenuList>
    </Menu>
  );
};

export default AddManagerMenu;
