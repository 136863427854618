import React from 'react';
import { createRoot } from "react-dom/client";
import './assets/styles/index.scss';
import { ChakraProvider } from '@chakra-ui/react';
import theme from './theme/theme';
import App from 'App';
import Container from 'shared/Container';
import { BrowserRouter } from 'react-router-dom';
import './i18n';
import {SocketProvider} from './shared/contexts/WebSocketContext'

const domNode = document.getElementById("root");
const root = createRoot(domNode as Element);

root.render(
  <ChakraProvider theme={theme}>
    {/* <React.StrictMode> */}
      <BrowserRouter>
        <Container>
          <SocketProvider>
            <App />
          </SocketProvider>
        </Container>
      </BrowserRouter>
    {/* </React.StrictMode> */}
  </ChakraProvider>,
);
