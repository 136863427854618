import React from 'react';
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Flex,
  Text,
} from '@chakra-ui/react';
import useGetColors from 'shared/hooks/useGetColors';
import Link from 'shared/components/Link';
import { PathsE } from 'shared/routes';
import MatIcon from 'components/MatIcon';

interface Props {
  title: string;
  backUrl?: {
    path: PathsE;
    pathsParams?: Record<string, string>;
  };
  breadcrumbs: {
    path: PathsE;
    pathsParams?: Record<string, string>;
    text: string;
  }[];
}

const PageTitle = ({ title, breadcrumbs = [], backUrl }: Props) => {
  const { gray100, gray800, gray700, blue800 } = useGetColors();
  return (
    <Flex alignItems="center" mb="20px">
      {backUrl && (
        <Link path={backUrl.path} pathsParams={backUrl.pathsParams}>
          <Button
            height="40px"
            width="40px"
            borderRadius="8px"
            bgColor={gray100}
          >
            <MatIcon size={24} name="arrow_back" color={gray800} variant="rounded" />
          </Button>
        </Link>
      )}
      <Box ml="16px">
        <Breadcrumb>
          {breadcrumbs.map(({ text, path, pathsParams }) => (
            <BreadcrumbItem
              color={gray700}
              key={path}
              fontSize="sm"
              mb="5px"
            >
              <Link color={gray700} path={path} pathsParams={pathsParams}>
                {text}
              </Link>
            </BreadcrumbItem>
          ))}
        </Breadcrumb>
        <Text
          color={blue800}
          bg="inherit"
          borderRadius="inherit"
          fontWeight="bold"
          fontSize="30px"
          _hover={{ color: { blue800 } }}
          _active={{
            bg: 'inherit',
            transform: 'none',
            borderColor: 'transparent',
          }}
          _focus={{
            boxShadow: 'none',
          }}
        >
          {title}
        </Text>
      </Box>
    </Flex>
  );
};

export default PageTitle;
