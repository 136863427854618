import {
  FormControl,
  FormLabel,
  Input
} from '@chakra-ui/react';
import { FieldValues, useFormContext } from 'react-hook-form';
import { FieldProps } from 'shared/types/filters';
import ErrorMessage from './ErrorMessage';
import useIsInvalid from './hooks/useIsInvalid';

const TextField = <TFieldValues extends FieldValues>({
  label,
  placeholder,
  type = 'text',
  name,
  size,
  ...props
}: FieldProps<'text'>) => {
  const { register } = useFormContext<TFieldValues>();
  const isInvalid = useIsInvalid(name);

return (
    <FormControl {...props} size={size} position="relative" isInvalid={isInvalid}>
      {label && (
        <FormLabel fontSize="md" fontWeight="500" mb="8px">
          {label}
        </FormLabel>
      )}
      <Input
        {...register(name)}
        type={type}
        placeholder={placeholder}
        size={size}
        fontSize="16px"
        isInvalid={isInvalid}
        autoComplete="off"
      />
      <ErrorMessage name={name} />
    </FormControl>
  );
};

export default TextField;
