import { useColorModeValue } from "@chakra-ui/react";

const useGetColors = () => {
  const textColor = useColorModeValue("gray.500", "white");
  const themeAccentColor = "brand";
  const brand500 = useColorModeValue("#5140E7", "#5140E7");
  const brand400 = useColorModeValue("#7666FF", "#7666FF");
  const white = useColorModeValue("white", "white");
  const textColorBrand = useColorModeValue(`${themeAccentColor}.500`, "white");
  const gray100 = useColorModeValue('#EDF2F7', '#EDF2F7');
  const gray800 = useColorModeValue('#1A202C', '#1A202C');
  const disabledSecondaryButtonText = useColorModeValue('#A0AEC0', '#A0AEC0');
  const gray50 = useColorModeValue('#F7FAFC', '#F7FAFC');
  const gray300 = useColorModeValue('#CBD5E0', '#CBD5E0');
  const gray500 = useColorModeValue('#718096', '#718096');
  const gray200 = useColorModeValue('#E2E8F0', '#E2E8F0');
  const red500 = useColorModeValue('#E91748', '#E91748');
  const red700 = useColorModeValue('#8C0C2A', '#8C0C2A');
  const red50 = useColorModeValue('#FCE8ED', '#FCE8ED');
  const red100 = useColorModeValue('#F9BECC', '#F9BECC');
  const red800 = useColorModeValue('#5D081D', '#5D081D');
  const green800 = useColorModeValue('#22543D', '#22543D');
  const green100 = useColorModeValue('#C6F6D5', '#C6F6D5');
	const mainText = useColorModeValue('navy.700', 'white');

  const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
	const secondaryText = useColorModeValue('gray.700', 'white');

  const blue500 = useColorModeValue('#4318FF', '#4318FF')
  const blue800 = useColorModeValue('#2B3674', '#2B3674')
  const gray700 = useColorModeValue('#707EAE', '#707EAE')
  const gray600 = useColorModeValue('#A3AED0', '#A3AED0')

  return {
    mainText,
    gray100,
    gray800,
    red100,
    green800,
    red800,
    gray600,
    green100,
    themeAccentColor,
    textColor,
    textColorBrand,
    boxBg,
    gray300,
    gray500,
    gray50,
    gray700,
    brand500,
    brand400,
    disabledSecondaryButtonText,
    gray200,
    white,
    red500,
    red50,
    red700,
    blue500,
    secondaryText,
    blue800
  }
};

export default useGetColors;
