import React, { forwardRef } from 'react';
import { Button, ButtonProps } from '@chakra-ui/react';
import { useMenuButton, UseMenuButtonProps } from '@chakra-ui/react';

type CustomMenuButtonProps = ButtonProps & UseMenuButtonProps;

const CustomMenuButton = forwardRef<HTMLButtonElement, CustomMenuButtonProps>((props, ref) => {
  const buttonProps = useMenuButton(props, ref);
  return (
    <Button ref={ref} {...buttonProps}>
      {props.children}
    </Button>
  );
});

CustomMenuButton.displayName = 'CustomMenuButton';

export default CustomMenuButton;
