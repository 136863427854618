import {
  Button,
  Center,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  SimpleGrid
} from '@chakra-ui/react'
import { FormProvider, UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next';
import { FilterField } from 'shared/types/filters';
import useGetColors from '../../hooks/useGetColors';
import useStateFromQueryParams from '../../hooks/useStateFromQueryParams';
import Field from '../form/Field';

interface FiltersDrawerProps {
  isOpen: boolean;
  values: UnknownRecord,
  fields: FilterField[],
  onClose: () => void;
  handleFilter: (values: UnknownRecord) => void;
  methods: UseFormReturn<UnknownRecord, FixMeLater, undefined>;
}

const FiltersDrawer = ({
  isOpen,
  fields,
  onClose,
  handleFilter,
  methods
}: FiltersDrawerProps) => {
  const { t } = useTranslation();
  const { blue500, blue800, white } = useGetColors();
  const { setSearchParams } = useStateFromQueryParams();

  const onSubmit = (data: UnknownRecord) => {
    handleFilter(data);
    setSearchParams(data);
    onClose();
  };

  return (
    <Drawer
      isOpen={isOpen}
      placement="right"
      onClose={onClose}
      size="md"
    >
      <DrawerOverlay/>
      <DrawerContent>
        <DrawerCloseButton/>
        <DrawerHeader marginTop={20}>
          {t('general.filters')}
        </DrawerHeader>
        <DrawerBody display="flex" flexDirection="column" alignItems="center">
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)} style={{ width: "100%" }}>
              <SimpleGrid columns={{base: 1, md: 2}} spacing={5} marginBottom={10} width="100%" position="relative" zIndex={1}>
                {fields.map(field => <Field key={field.name} {...field} />)}
              </SimpleGrid>
              <Center>
                <Button type="submit" bgColor={blue500} color={white} width="70%" _hover={{background: blue800}}>
                  {t('buttons.apply')}
                </Button>
              </Center>
            </form>
          </FormProvider>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

export default FiltersDrawer;
