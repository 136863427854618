import {
  FormControl,
  FormLabel,
  Input,
  InputGroup,
} from '@chakra-ui/react';
// import MatIcon from 'components/MatIcon';
import { forwardRef } from 'react';
import DatePicker from 'react-datepicker';
import {
  Path,
  PathValue,
  useFormContext
} from 'react-hook-form';
import { FieldProps } from 'shared/types/filters';
import { formatStringToDate, formatDateToString } from 'shared/utils/formattings';
import ErrorMessage from './ErrorMessage';
import useIsInvalid from './hooks/useIsInvalid';

const DateField = <F extends UnknownRecord>({
  label,
  placeholder,
  name,
  showTime = false,
  size,
  ...props
}: FieldProps<'date'>) => {
  const {
    register,
    setValue,
    watch,
  } = useFormContext<F>();
  const isInvalid = useIsInvalid(name);

  const value = watch(name);

  const selectedValue = formatStringToDate(value as string);

  const handleChange = (date: Date) => {
    if (date) {
      setValue(name, formatDateToString(date) as PathValue<F, Path<F>>);
    } else {
      setValue(name, null);
    }
  };

  const customDateInput = ({ value, onClick, onChange }: FixMeLater, ref: FixMeLater) => (
    <Input
      size={size}
      autoComplete="off"
      background="white"
      value={value}
      ref={ref}
      onClick={onClick}
      onChange={onChange}
    />
  );
  customDateInput.displayName = "DateInput";
  // const icon = <MatIcon style={{ height: '100%' }} name="calendar_month" />;

  const CustomInput = forwardRef(customDateInput);

  return (
    <FormControl {...props} size={size} position="relative" isInvalid={isInvalid}>
      {label && (
        <FormLabel fontSize="md" fontWeight="500" mb="8px">
          {label}
        </FormLabel>
      )}
      <InputGroup>
        <DatePicker
          {...register(name)}
          onChange={handleChange}
          placeholderText={placeholder}
          isClearable
          selected={selectedValue}
          className="react-datapicker__input-text"
          showTimeSelect={showTime}
          // maxDate={Date()}
          dateFormat={showTime ? "dd/MM/yyyy HH:mm:ss" : "dd/MM/yyyy"}
          timeFormat="HH:mm"
          calendarStartDay={1}
          customInput={<CustomInput />}
        />
        {/* <InputRightElement height="100%" color="gray.500" children={icon} /> */}
      </InputGroup>
      <ErrorMessage name={name} />
    </FormControl>
  );
};

export default DateField;
