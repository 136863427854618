import { Button, useDisclosure } from '@chakra-ui/react';
import {useEffect, useState} from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import TextField from 'shared/components/form/TextField';
import useGetColors from 'shared/hooks/useGetColors';
import { fillFormErrors } from 'shared/utils/devUtils';
import { useTranslation } from 'react-i18next';
import SelectField from '../../components/form/SelectField'
import Field from '../../components/form/Field';
import { useGetChannels } from '../../api/requests/channels';
import { sourceTypeOptions } from '../../form-values/signInQrCode';
import { useSignInSendCode } from '../../api/requests/managers';
import PageTitle from '../admin/PageTitle';
import Card from '../../components/horizon-ui/card/Card';
import { useGetSignInBreadcrumbs } from './constants';
import { PathsE } from '../../routes';
import { SourceType } from 'shared/api/types/channels';
import { ResourceTypeT } from 'shared/api/types/subscribers';
import { useGetBots } from 'shared/api/requests/bots';
import PhoneField from '../../components/form/PhoneField'
import { getSignInSendCodeValues, signInSendCodeFormResolver, SignInSendCodeFormT } from '../../form-values/signInSendCode';
import CodeModal from './CodeModal';
import CodeAndPasswordModal from './CodeAndPasswordModal';

interface Props {
  passwordMode?: boolean,
}

const SignInSendCode = ({ passwordMode }: Props) => {
  const [sourceType, setSourceType] = useState<ResourceTypeT>('channel');

  const { t } = useTranslation();
  const { mutate, isPending } = useSignInSendCode();
  const { white, brand500, brand400 } = useGetColors();
  const methods = useForm<SignInSendCodeFormT>({
    defaultValues: getSignInSendCodeValues(),
    resolver: signInSendCodeFormResolver,
    mode: "onChange"
  });
  const { formState: { isValid }, trigger, getValues } = methods;
  const { isOpen, onOpen, onClose } = useDisclosure();

  const breadcrumbs = useGetSignInBreadcrumbs();

  const onSubmit: SubmitHandler<SignInSendCodeFormT> = (values) => {
    mutate(values, {
      onSuccess: () => {
        onOpen();
      },
      onError: (error) => fillFormErrors(methods.setError, error),
    });
  };

  const fieldProps = {
    mb: '20px',
    w: {
      base: '100%',
      md: '50%',
    },
  };

  useEffect(() => {
    trigger();
  }, [])

  return (
    <>
      <PageTitle
        title={t('managers.signInSendCode')}
        breadcrumbs={breadcrumbs}
        backUrl={{
          path: PathsE.MANAGERS,
        }}
      />
      <Card mb="20px" borderRadius="20px" px="30px">
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <SelectField<SignInSendCodeFormT>
              options={sourceTypeOptions}
              name="sourceType"
              defaultValue="channels"
              label={`${t('forms.source')} *`}
              placeholder={t('forms.source')}
              onChange={(sourceType: SourceType) => setSourceType(sourceType)}
              {...fieldProps}
            />
            {sourceType === 'bot' ? (
              <Field
                type="async-select"
                name="sourceId"
                label={`${t('forms.sourceId')} *`}
                placeholder={t('forms.selectSourceId')}
                optionsQuery={useGetBots}
                labelName="name"
                valueName="id"
                defaultValue={null}
                {...fieldProps}
              />
            ) : (
              <Field
                type="async-select"
                name="sourceId"
                label={`${t('forms.sourceId')} *`}
                placeholder={t('forms.selectSourceId')}
                optionsQuery={useGetChannels}
                labelName="name"
                valueName="id"
                defaultValue={null}
                {...fieldProps}
              />
            )}
            <PhoneField
              name="phoneNumber"
              label={`${t('forms.phoneNumber')} *`}
              placeholder={t('forms.enterPhoneNumber')}
              {...fieldProps}
            />
            <TextField<SignInSendCodeFormT>
              name="apiId"
              label={`${t('forms.apiId')} *`}
              defaultValue={6}
              placeholder={t('forms.enterApiId')}
              style={{ marginTop: '20px' }}
              {...fieldProps}
            />
            <TextField<SignInSendCodeFormT>
              name="apiHash"
              label={`${t('forms.apiHash')} *`}
              placeholder={t('forms.enterApiHash')}
              defaultValue="eb06d4abfb49dc3eeb1aeb98ae0f581e"
              {...fieldProps}
            />
            <Button
              color={white}
              borderRadius="8px"
              _hover={{
                bgColor: brand400,
              }}
              bgColor={brand500}
              isLoading={isPending}
              w="100px"
              type="submit"
              isDisabled={!isValid}
            >
              {t('buttons.next')}
            </Button>
          </form>
        </FormProvider>
      </Card>
      {passwordMode
        ? <CodeAndPasswordModal isOpen={isOpen} onClose={onClose} mainData={getValues()} />
        : <CodeModal isOpen={isOpen} onClose={onClose} mainData={getValues()} />
      }
    </>
  );
};

export default SignInSendCode
