import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { useMemo } from "react";
import Table from "shared/components/table/Table";
import { useTranslation } from "react-i18next";
import { PathsE, LayoutsE } from "shared/routes";
import { LinkSubscribersT } from '../../api/types/links';
import { Box } from '@chakra-ui/react';

interface Props {
  values: LinkSubscribersT[],
}

const LinkSubscribers = ({ values }: Props) => {
  const { t } = useTranslation();
  const columnHelper = createColumnHelper<LinkSubscribersT>();

  const columns = useMemo(
    () => [
      columnHelper.accessor('username', {
        header: t('subscribers.table.username'),
        id: 'username',
      }),
      columnHelper.accessor('subscribedAt', {
        header: t('links.table.subscribedAt'),
        id: 'subscribedAt',
      }),
    ],
    [t]
  );

  return (
    <Box mt={16}>
      <Table
        data={values}
        columns={columns as ColumnDef<LinkSubscribersT>[]}
        clickable
        linkPrefix={`/${LayoutsE.DASHBOARD}/${PathsE.SUBSCRIBERS}/`}
      />
    </Box>
  )
}

export default LinkSubscribers;
