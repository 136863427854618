import { FormControl, FormLabel } from '@chakra-ui/react';
import { ChakraStylesConfig, Select } from 'chakra-react-select';
import { ListQueryRequestType } from 'shared/api/types/typedQueries';
import { FieldProps } from 'shared/types/filters';
import ErrorMessage from './ErrorMessage';
import useIsInvalid from './hooks/useIsInvalid';
import useSelectField from './hooks/useSelectField';

export const chakraStyles: ChakraStylesConfig<FixMeLater> = {
  placeholder: (provided) => ({
    ...provided,
    fontSize: '16px'
  }),
  clearIndicator: (provided) => ({
    ...provided,
    width: 'auto',
    height: 'auto',
    margin: '0',
    padding: '4px'
  }),
  crossIcon: (provided) => ({
    ...provided,
    width: '6px',
    height: '6px',
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    svg: {
      width: '14px',
      height: '14px',
    },
  }),
  multiValue: (provided) => ({
    ...provided,
    padding: '6px'
  }),
  valueContainer: (provided) => ({
    ...provided,
    paddingRight: '6px'
  }),
  option: (provided) => ({
    ...provided,
    fontSize: '14px'
  }),
  noOptionsMessage: (provided) => ({
    ...provided,
    fontSize: '14px'
  }),
  input: (provided) => ({
    ...provided,
    fontSize: '14px'
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: '0',
    marginRight: '6px',
    marginLeft: '0',
    svg: {
      width: '14px',
      height: '14px',
    },
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    fontSize: '14px',
  }),
};

const AsyncMultiSelectField = <O extends ListQueryRequestType>({
  label,
  size = 'md',
  fullWidth,
  ...props
}: FieldProps<'async-select', 'input', O>) => {
  const selectProps = useSelectField<O>({ ...props, isMulti: true });
  const isInvalid = useIsInvalid(props.name);
  console.log('AsyncMultiSelectField render', props);
  return (
    <FormControl
      mb={'20px'}
      w={fullWidth ? '100%' : { base: '100%', md: '50%' }}
      isInvalid={isInvalid}
      size={size} position="relative">
      {label && (
        <FormLabel fontSize="md" fontWeight="500" mb="8px">
          {label}
        </FormLabel>
      )}
      <Select
        {...selectProps}
        chakraStyles={chakraStyles}
        useBasicStyles
        // classNamePrefix={'chakra-select' + `${small ? '--small' : ''}`}
        colorScheme="green"
        size={size}
      />
      <ErrorMessage name={props.name} />
    </FormControl>
  );
};

export default AsyncMultiSelectField;
