import { yupResolver } from '@hookform/resolvers/yup';
import { Resolver } from 'react-hook-form';


import * as Yup from 'yup';
import initDefaultSchema from '../yupConfig';
import {CodeAndPasswordT, CodeAndPasswordValuesT, CodeT, CodeValuesT, SignInSendCodeT} from '../api/types/managers'
import { sourceType } from './signInQrCode';
import {isValidPhoneNumber} from 'libphonenumber-js'

initDefaultSchema();

export type SignInSendCodeFormT = SignInSendCodeT['request'];

export const getSignInSendCodeValues = (): SignInSendCodeFormT => ({
  phoneNumber: '',
  apiId: 6,
  apiHash: 'eb06d4abfb49dc3eeb1aeb98ae0f581e',
  sourceId: '',
  sourceType: 'channel',
  isActive: true,
  isDeleted: true
});

const SignInSendCodeFormSchema: Yup.ObjectSchema<SignInSendCodeFormT> = Yup.object({
  phoneNumber: Yup.string().required().test('is-valid-phone', 'Phone number is invalid', (value) => (
    value ? isValidPhoneNumber(`+${value}`) : false
  )),
  apiId: Yup.number().required(),
  apiHash: Yup.string().required(),
  sourceId: Yup.string().required(),
  sourceType: Yup.string().oneOf(sourceType).required(),
  isActive: Yup.boolean().required(),
  isDeleted: Yup.boolean().required(),
});

export const signInSendCodeFormResolver = yupResolver(SignInSendCodeFormSchema) as Resolver<SignInSendCodeFormT>;


export type CodeFormT = CodeT['request'];

export const getCodeValues = (): CodeValuesT => ({
  code: '',
});

const CodeFormSchema: Yup.ObjectSchema<CodeValuesT> = Yup.object({
  code: Yup.string().required(),
});

export const codeFormResolver = yupResolver(CodeFormSchema) as Resolver<CodeValuesT>;

export type CodeAndPasswordFormT = CodeAndPasswordT['request'];

export const getCodeAndPasswordValues = (): CodeAndPasswordValuesT => ({
  code: '',
  password: ''
});

const CodeAndPasswordFormSchema: Yup.ObjectSchema<CodeAndPasswordValuesT> = Yup.object({
  code: Yup.string().required(),
  password: Yup.string().required()
});

export const codeAndPasswordFormResolver = yupResolver(CodeAndPasswordFormSchema) as Resolver<CodeAndPasswordValuesT>;
