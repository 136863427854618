import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay, Text,
} from '@chakra-ui/react'
import useGetColors from 'shared/hooks/useGetColors';
import React from 'react';
import { useTranslation } from 'react-i18next';
import QRCode from 'react-qr-code';
import {QRData} from '../../api/types/managers';
import {HSeparator} from '../../components/horizon-ui/separator/Separator';

interface Props {
  timeLeft: number;
  attempts: number;
  qrData: QRData;
  isOpen: boolean;
  onClose: VoidFunction;
}

const MAX_ALLOWED_ATTEMPTS = 3;

const QrModal = ({ qrData, timeLeft, attempts, isOpen, onClose }: Props) => {
  const { t } = useTranslation();
  const { gray800, gray100, red500 } = useGetColors();

  const timeExpired = attempts === MAX_ALLOWED_ATTEMPTS && timeLeft === 0;

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody display="flex" flexDirection="column" alignItems="center" paddingTop={10}>
          {!timeExpired ? (
            qrData && qrData.url && (
              <>
                <QRCode value={qrData.url} />
                <HSeparator mt="40px" width="calc(100% + 40px)" />
                <Text fontSize="x-large" mt={5} mb={2}>
                  {t('managers.timeLeft', { timeLeft })}
                </Text>
                <Text fontSize="x-large">
                  {t('managers.attemptsLeft', { attemptsLeft: attempts })}
                </Text>
                <HSeparator my="20px" width="calc(100% + 40px)" />
              </>
            )
          ) : (
            <Text fontSize="lg" color={red500} px={8}>
              {t('managers.timeExpired')}
            </Text>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            color={gray800}
            bgColor={gray100}
            borderRadius="8px"
            onClick={onClose}
            w="100px"
          >
            {t('buttons.cancel')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default QrModal;
