import React, { useMemo } from 'react';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { SubscriberMessageT } from 'shared/api/types/subscribers';
import { useTranslation } from 'react-i18next';
import { formatDateToHuman } from 'shared/utils/formattings';
import Table from "shared/components/table/Table";
import MatIcon from 'components/MatIcon';
import useGetColors from 'shared/hooks/useGetColors';
import { Flex, Text } from '@chakra-ui/react';

interface Props {
  messages: SubscriberMessageT[],
}

const SubscriberMessages = ({ messages }: Props) => {
  const columnHelper = createColumnHelper<SubscriberMessageT>();
  const { t } = useTranslation();
  const { brand500 } = useGetColors();

  const columns = useMemo(
    () => [
      columnHelper.accessor('username', {
        header: t('subscribers.table.username'),
        id: 'username',
      }),
      columnHelper.accessor('type', {
        header: t('subscribers.table.type'),
        id: 'type',
        cell: ({ getValue }) => {
          const isSent = getValue() === 'sent';
          return (
            <Flex alignItems="center" gap={2}>
              <Text as="span">{getValue()}</Text>
              <MatIcon name={isSent ? "support_agent" : "person"} color={isSent ? 'green' : brand500} />
            </Flex>
          )
        },
      }),
      columnHelper.accessor('isSent', {
        header: t('subscribers.table.isSent'),
        id: 'isSent',
        cell: ({ getValue }) => {
          const val = getValue();
          return (
            <Flex alignItems="center" gap={2}>
              <Text as="span">{t(val ? 'yes' : 'no')}</Text>
              <MatIcon name={val ? "check_circle" : "warning"} color={val ? 'green' : 'red'} />
            </Flex>
          )
        },
      }),
      columnHelper.accessor('message', {
        header: t('subscribers.table.message'),
        id: 'message',
      }),
      columnHelper.accessor('sendAt', {
        header: t('subscribers.table.sendAt'),
        id: 'sendAt',
        cell: ({ getValue }) => formatDateToHuman(getValue()),
        meta: {
          isNumeric: true,
          styles: {
            sizes: {
              width: '150px'
            }
          }
        }
      }),
    ],
    [t]
  );

  return (
    <Table data={messages} columns={columns as ColumnDef<SubscriberMessageT>[]} />
  );
}

export default SubscriberMessages;
