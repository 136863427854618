import { IconButton, Input, InputGroup, InputLeftElement, useColorModeValue } from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';

interface Props {
	variant?: string;
	background?: string;
	placeholder?: string;
	borderRadius?: string | number;
	[x: string]: FixMeLater;
	searchValue: string;
	handleChangeSearch: (value: string) => void;
}

export function SearchBar(props: Props) {
  const { t } = useTranslation();
	// Pass the computed styles into the `__css` prop
	const { variant, background, placeholder, borderRadius, searchValue, handleChangeSearch, ...rest } = props;
	// Chakra Color Mode
	const searchIconColor = useColorModeValue('gray.700', 'white');
	const inputBg = useColorModeValue('secondaryGray.300', 'navy.900');
	const inputText = useColorModeValue('gray.700', 'gray.100');
	return (
		<InputGroup w={{ base: '100%', md: '200px' }} {...rest}>
			<InputLeftElement>
				<IconButton
					aria-label='search'
					bg='inherit'
					borderRadius='inherit'
					_active={{
						bg: 'inherit',
						transform: 'none',
						borderColor: 'transparent'
					}}
					_focus={{
						boxShadow: 'none'
					}}
					icon={<SearchIcon color={searchIconColor} w='15px' h='15px' />}
				/>
			</InputLeftElement>
			<Input
				variant={variant ?? 'search'}
				fontSize='sm'
				bg={background ? background : inputBg}
				color={inputText}
				fontWeight='500'
				autoComplete="off"
				_placeholder={{ color: 'gray.400', fontSize: '14px' }}
				borderRadius={borderRadius ? borderRadius : '30px'}
				placeholder={placeholder ? placeholder : t('buttons.search')}
				value={searchValue}
				onChange={(e) => handleChangeSearch(e.target.value)}
			/>
		</InputGroup>
	);
}
