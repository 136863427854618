import React, { useEffect, useState } from 'react';
import { Button, Flex } from '@chakra-ui/react';
import i18next from 'i18next';
import { Locals } from '../utils/constants';
import useGetColors from '../hooks/useGetColors';

const LanguageSwitcher = () => {
  const [, setCurrentLanguage] = useState(i18next.language);
  const {gray300, blue800} = useGetColors();

  useEffect(() => {
    const handleLanguageChange = (lng: string) => {
      setCurrentLanguage(lng);
    };

    i18next.on('languageChanged', handleLanguageChange);

    return () => {
      i18next.off('languageChanged', handleLanguageChange);
    };
  }, []);

  const changeLanguage = (lng: string) => {
    i18next.changeLanguage(lng);
  };

  return (
    <Flex gap={1}>
      <Button
        onClick={() => changeLanguage(Locals.EN)}
        background={gray300}
        color={blue800}
        size="sm"
        isDisabled={i18next.language === Locals.EN}
      >
        En
      </Button>
      <Button
        onClick={() => changeLanguage(Locals.RU)}
        background={gray300}
        color={blue800}
        size="sm"
        isDisabled={i18next.language === Locals.RU}
      >
        Ru
      </Button>
    </Flex>
  )
};

export default LanguageSwitcher;
