import { Text } from '@chakra-ui/react';
import { useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useSignup} from 'shared/api/requests/auth'
import PasswordField from 'shared/components/form/PasswordField';
import TextField from 'shared/components/form/TextField';
import Link from 'shared/components/Link';
import ShadowCard from 'shared/components/ShadowCard';
import SubmitButton from 'shared/components/SubmitButton';
import { SignupFormT, useSignupSchema } from 'shared/form-values/auth';
import useGetColors from 'shared/hooks/useGetColors';
import useTypedHistory from 'shared/hooks/useTypedHistory';
import { LayoutsE, PathsE } from 'shared/routes';
import { fillFormErrors, getCookies } from 'shared/utils/devUtils';
import { useTranslation } from 'react-i18next';

const SignUp = () => {
  const { t } = useTranslation();
  const { signupValues, signupResolver } = useSignupSchema();
  const otpCodeExpirationTimeFromCookie = getCookies('otpCodeExpirationTime');
  const otpCodeExpirationTime = otpCodeExpirationTimeFromCookie - new Date().getTime();
  const push = useTypedHistory();
  const { mutate, isPending } = useSignup();
  const methods = useForm<SignupFormT>({
    defaultValues: signupValues,
    resolver: signupResolver,
    mode: "onChange"
  });

  useEffect(() => {
    methods.trigger();
  }, []);

  const { textColorBrand } = useGetColors();
  const onSubmit: SubmitHandler<SignupFormT> = (values) => mutate(values, { onError: (error) => fillFormErrors<SignupFormT>(methods.setError, error, methods.getValues()) });

  useEffect(() => {
    if (otpCodeExpirationTime > 0) push(PathsE.TWO_FA, LayoutsE.AUTH);
  }, [otpCodeExpirationTime])

  return (
    <ShadowCard titleText={t('auth.signUp')}>
      <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <TextField<SignupFormT>
          name="name"
          type="text"
          label="Name"
          placeholder={t('auth.enterName')}
          mb="24px"
        />
        <TextField<SignupFormT>
          name="surname"
          type="text"
          label={t('auth.surname')}
          placeholder={t('auth.enterSurname')}
          mb="24px"
        />
        <TextField<SignupFormT>
          name="email"
          type="email"
          label={t('auth.email')}
          placeholder={t('auth.enterEmail')}
          mb="24px"
        />
        <PasswordField<SignupFormT> label={t('auth.password')} placeholder={t('auth.enterPassword')} />
        <SubmitButton label={t('buttons.next')} mt="40px" mb="24px" isLoading={isPending} />
        <Link layout={LayoutsE.AUTH} path={PathsE.LOGIN}>
          <Text
            color={textColorBrand}
            fontSize="md"
            fontWeight="500"
            textAlign="center"
          >
            {t('auth.signIn')}
          </Text>
        </Link>
      </form>
      </FormProvider>
    </ShadowCard>
  );
};

export default SignUp;
