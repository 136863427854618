import { Box, Button } from '@chakra-ui/react';
import { useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useCreateBot } from 'shared/api/requests/bots';
import Card from 'shared/components/horizon-ui/card/Card';
import { HSeparator } from 'shared/components/horizon-ui/separator/Separator';
import Link from 'shared/components/Link';
import {
  CreateBotFormT,
  createBotResolver,
  getCreateBotValues
} from 'shared/form-values/bots';
import useGetColors from 'shared/hooks/useGetColors';
import { PathsE } from 'shared/routes';
import { fillFormErrors } from 'shared/utils/devUtils';
import PageTitle from '../admin/PageTitle';
import BotForm from './BotForm';

const Create = () => {
  const { t } = useTranslation();
  const { mutate, isPending } = useCreateBot();
  const { gray800, white, gray100, brand500, brand400 } = useGetColors();
  const methods = useForm<CreateBotFormT>({
    defaultValues: getCreateBotValues(),
    resolver: createBotResolver,
    mode: "onChange"
  });
  const { formState: { isValid }, trigger } = methods;

  useEffect(() => {
    trigger();
  }, []);

  const onSubmit: SubmitHandler<CreateBotFormT> = (values) => mutate(values, { onError: (error) => fillFormErrors(methods.setError, error) });

  const breadcrumbs = [
    {
      path: PathsE.HOME,
      text: t('general.home'),
    },
    {
      path: PathsE.BOTS,
      text: t('bots.bots'),
    },
  ]

  return (
    <Box>
      <PageTitle title={t('bots.createBot')} breadcrumbs={breadcrumbs} backUrl={{
        path: PathsE.BOTS
      }} />
      <Card mb="20px" borderRadius="20px" px="30px">
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <BotForm isCreateMode />
            <HSeparator my="20px" mx="-20px" width="calc(100% + 40px)" />
            <Link path={PathsE.BOTS}>
              <Button
                color={gray800}
                bgColor={gray100}
                borderRadius="8px"
                w="100px"
              >
                Cancel
              </Button>
            </Link>
            <Button
              color={white}
              borderRadius="8px"
              _hover={{
                bgColor: brand400,
              }}
              bgColor={brand500}
              ml="20px"
              isLoading={isPending}
              w="100px"
              type="submit"
              isDisabled={!isValid}
            >
              Create
            </Button>
          </form>
        </FormProvider>
      </Card>
    </Box>
  );
};

export default Create;
