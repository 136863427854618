import {
  Box,
  Button,
  Center,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import MatIcon from 'components/MatIcon';
import useGetColors from 'shared/hooks/useGetColors';
import { useTranslation } from 'react-i18next'

interface Props {
  title: string;
  text: string;
  isOpen: boolean;
  onClose: VoidFunction;
  onSubmit: VoidFunction;
}

const DeleteModal = ({ title, text, isOpen, onClose, onSubmit }: Props) => {
  const {
    gray800,
    white,
    gray100,
    gray500,
    red500,
    red700,
    red50,
  } = useGetColors();
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box
            margin="auto"
            mb="24px"
            borderRadius="50%"
            backgroundColor={red50}
            w="100px"
            h="100px"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <MatIcon name="delete" color={red500} size={48} />
          </Box>
          <Text color={gray500} textAlign="center">
            {text}
          </Text>
        </ModalBody>
        <ModalFooter>
          <Center w="100%">
            <Button
              color={gray800}
              bgColor={gray100}
              borderRadius="8px"
              onClick={onClose}
              w="100px"
            >
              {t('buttons.cancel')}
            </Button>
            <Button
              color={white}
              bgColor={gray100}
              borderRadius="8px"
              backgroundColor={red500}
              _hover={{
                background: red700,
              }}
              ml="20px"
              w="100px"
              onClick={() => {
                onSubmit();
                onClose();
              }}
            >
              {t('buttons.delete')}
            </Button>
          </Center>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DeleteModal;
