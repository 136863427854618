import { MultiValue, SingleValue, useChakraSelectProps } from "chakra-react-select";
import { useState, useMemo } from "react";
import { useController } from "react-hook-form";
import { ListQueryRequestType } from "shared/api/types/typedQueries";
import { useDebounce } from "shared/hooks/useDebounce";
import { FieldProps, OptionKeys, OptionV } from "shared/types/filters";
import useIsInvalid from "./useIsInvalid";

type OptionValue<O extends ListQueryRequestType> = {
  value: OptionV<O>[OptionKeys<O>],
  label: OptionV<O>[OptionKeys<O>],
}

const handleSelectMultiValue = <O extends ListQueryRequestType>(
  options: MultiValue<OptionValue<O>>,
  callback: (val: OptionValue<O>['value'][]) => void,
) => {
  const optionValues = options.map((option) => option.value);
  callback(optionValues);
};

const handleSelectSingleValue = <O extends ListQueryRequestType>(
  option: SingleValue<OptionValue<O>>,
  callback: (val: OptionValue<O>['value']) => void,
) => {
  callback(option.value);
};

const useSelectField = <O extends ListQueryRequestType>({
  name,
  optionsQuery,
  labelName: propsLabelName,
  valueName: propsValueName,
  size,
  placeholder,
  isMulti,
}: Omit<FieldProps<'async-select', 'input', O>, 'label'>) => {
  const {
    field: { onChange, value, ...fieldProps },
  } = useController({ name });
  const isInvalid = useIsInvalid(name);
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce<string>(search, 500);
  const [wasOpened, setWasOpened] = useState(false);
  
  const { data, isFetching } = optionsQuery(
    {
      params: { search: debouncedSearch },
      pathParams: undefined
    },
    { enabled: wasOpened }
  );
  const labelName = propsLabelName || ('label' as keyof O);
  const valueName = propsValueName || ('value' as keyof O);

  const optionsToRender = useMemo(
    () =>
      data?.pages
        .flatMap((page) => page.data)
        .map((item) => ({
          label: (item as FixMeLater)[labelName],
          value: (item as FixMeLater)[valueName],
        })) ?? [],
    [data, labelName, valueName]
  );

  type MulOrSin<T extends boolean, Option = O> = T extends true ? MultiValue<OptionValue<Option>> : SingleValue<OptionValue<Option>>;
  const handleSelectValue = (value: MulOrSin<typeof isMulti>) => {
    const callback = (val: MulOrSin<typeof isMulti, OptionValue<O>['value']>) => {
      onChange(val);
      setSearch('');
    }

    if (isMulti) {
      handleSelectMultiValue<O>(value as MulOrSin<true>, callback as FixMeLater)
    } else {
      handleSelectSingleValue<O>(value as MulOrSin<false>, callback as FixMeLater)
    }
  };

  const selectProps = useChakraSelectProps<OptionValue<O>, typeof isMulti>({
    ...fieldProps,
    isMulti,
    value: isMulti
      ? optionsToRender.filter((option) => value.includes(option.value))
      : optionsToRender.find((option) => value === option.value),
    onChange: handleSelectValue,
    isInvalid,
    placeholder,
    isLoading: isFetching,
    options: optionsToRender,
    size
  });

  const onMenuOpen = () => () => setWasOpened(true);
  const onMenuClose = () => () => setWasOpened(false);
  const onInputChange = (value: string) => setSearch(value);

  return {
    onMenuOpen,
    onMenuClose,
    onInputChange,
    ...selectProps,
  }
}

export default useSelectField;
