import React, { ReactNode } from 'react'
import { Menu, MenuButton, IconButton, MenuList, MenuItem, Text } from '@chakra-ui/react';
import useGetColors from 'shared/hooks/useGetColors';
import { PathsE } from 'shared/routes';
import Link from './Link';
import useHandleCopy from 'shared/hooks/useHandleCopy';
import { ModelFieldsT } from 'shared/api/types/common';
import MatIcon from 'components/MatIcon';

type Props<TData> = {
  data: TData,
  isModelView?: boolean,
  isHideEdit?: boolean,
  children?: ReactNode,
  handleActivate: (id: string) => void,
  handleDeactivate: (id: string) => void,
  viewLink?: PathsE,
  // TODO add more specific type
  pathsParams?: Record<string, string>
}

type CommonModelT = ModelFieldsT;
// type CommonModelT = ModelFieldsT & DeletedModelT;

const MainItemMenu = <TData extends UnknownRecord & CommonModelT>({
  children, data, handleActivate, handleDeactivate, viewLink, pathsParams, isModelView = false, isHideEdit = false
}: Props<TData>) => {
  const {
    gray100,
    gray500,
    gray700,
  } = useGetColors();
  const handleCopy = useHandleCopy();

  return (
    <Menu>
      <MenuButton
        my={isModelView ? "" : "-10px"}
        as={IconButton}
        aria-label="Options"
        icon={<MatIcon name="more_vert" size={24} color={gray500} />}
        variant={isModelView ? "outline" : ""}
        borderRadius="8px"
      />
      <MenuList>
        {viewLink ? (
          isModelView ? (isHideEdit ? null :
            <Link
              path={viewLink}
              pathsParams={pathsParams}
            >
              <MenuItem
                _hover={{
                  backgroundColor: gray100,
                }}
              >
                <MatIcon name="edit" />
                <Text color={gray700} ml="8px" fontSize="sm">Edit</Text>
              </MenuItem>
            </Link>
          ) : (
            <Link
              path={viewLink}
              pathsParams={pathsParams}
            >
              <MenuItem
                _hover={{
                  backgroundColor: gray100,
                }}
              >
                <MatIcon name="folder_open" />
                <Text color={gray700} ml="8px" fontSize="sm">View</Text>
              </MenuItem>
            </Link>
          )
        ) : null}

        {data.isActive ? (
          <MenuItem
            _hover={{
              backgroundColor: gray100,
            }}
            onClick={() => handleDeactivate(data.id)}
          >
            <MatIcon name="do_not_disturb_on" />
            <Text color={gray700} ml="8px" fontSize="sm">Deactivate</Text>
          </MenuItem>
        ) : (
          <MenuItem
            _hover={{
              backgroundColor: gray100,
            }}
            onClick={() => handleActivate(data.id)}
          >
            <MatIcon name="add_circle" />
            <Text color={gray700} ml="8px" fontSize="sm">Activate</Text>
          </MenuItem>
        )}
        <MenuItem
          _hover={{
            backgroundColor: gray100,
          }}
          onClick={() => handleCopy(data.id)}
        >
          <MatIcon name="content_copy" />
          <Text color={gray700} ml="8px" fontSize="sm">Copy UUID</Text>
        </MenuItem>
        {children}
      </MenuList>
    </Menu>
  )
}

export default MainItemMenu;
