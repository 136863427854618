import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  HTMLChakraProps,
  Icon,
  Input,
  InputGroup,
  InputRightElement
} from '@chakra-ui/react';
import {
  ErrorMessage,
  FieldValuesFromFieldErrors
} from '@hookform/error-message';
import { useState } from 'react';
import {
  FieldErrors,
  FieldName,
  Path,
  useFormContext
} from 'react-hook-form';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { RiEyeCloseLine } from 'react-icons/ri';
import { getInputStyles } from 'shared/utils/devUtils';

type Props<F extends UnknownRecord> = Modify<
  HTMLChakraProps<'input'>,
  {
    name?: Path<F>;
    label?: string;
    placeholder?: string;
    small?: boolean;
  }
>;

const PasswordField = <F extends UnknownRecord>({
  label = 'Password',
  name = 'password' as Path<F>,
  placeholder = 'Enter your password',
  small,
  ...props
}: Props<F>) => {
  const { register, formState: {errors, touchedFields } } = useFormContext<F>()
  const [show, setShow] = useState(false);
  const handleClick = () => setShow((prevState) => !prevState);

  const isInvalid = !!errors[name] && !!touchedFields[name];

  return (
    <FormControl {...props} size="md" position="relative" isInvalid={isInvalid}>
      {label && (
        <FormLabel fontSize="md" fontWeight="500" mb="8px">
          {label}
        </FormLabel>
      )}
      <InputGroup size="md">
        <Input
          {...register(name)}
          {...getInputStyles(small)}
          fontSize="md"
          placeholder={placeholder}
          size="lg"
          isInvalid={isInvalid}
          type={show ? 'text' : 'password'}
          autoComplete="off"
          formNoValidate
        />
        <InputRightElement display="flex" alignItems="center">
          <Icon
            color="gray.400"
            _hover={{ cursor: 'pointer' }}
            as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
            onClick={handleClick}
          />
        </InputRightElement>
      </InputGroup>
      {isInvalid && (
        <FormErrorMessage>
          <ErrorMessage
            errors={errors}
            name={
              (name as unknown) as FieldName<
                FieldValuesFromFieldErrors<FieldErrors<F>>
              >
            }
          />
        </FormErrorMessage>
      )}
    </FormControl>
  );
};

export default PasswordField;
