import {
  Box,
  Button,
  Flex,
  MenuItem,
  Text,
  useDisclosure
} from '@chakra-ui/react';
import MatIcon from 'components/MatIcon';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  useActivateOneBot, useDeactivateOneBot,
  useDeleteBot, useGetOneBot
} from 'shared/api/requests/bots';
import Card from 'shared/components/horizon-ui/card/Card';
import Link from 'shared/components/Link';
import Loader from 'shared/components/Loader';
import MainItemMenu from 'shared/components/MainItemMenu';
import MainModelInfo from 'shared/components/MainModelInfo';
import useGetColors from 'shared/hooks/useGetColors';
import { PathsE, PathsParams } from 'shared/routes';
import DeleteModal from '../admin/DeleteModal';
import PageTitle from '../admin/PageTitle';
import BotInfo from './BotInfo';

const ViewBot = () => {
  const { t } = useTranslation();
  const { botId } = useParams<PathsParams[PathsE.SHOW_BOT]>();
  const {
    gray800,
    gray700,
    gray100,
  } = useGetColors();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const requestParams = {
    pathParams: { botId },
    params: {},
  };
  const { mutate: activateMutation } = useActivateOneBot();
  const { mutate: deactivateMutation } = useDeactivateOneBot();
  const handleActivate = (botId: string) => activateMutation({ botId });
  const handleDeactivate = (botId: string) => deactivateMutation({ botId });

  const { mutate: deleteMutate } = useDeleteBot(requestParams);
  const { data, isLoading } = useGetOneBot(requestParams);

  const handleDelete = () => onOpen();

  const renderName = () => `
          ${data.name ? data.name + ' ' : ''}
          ${data.username ? data.username + ' ' : ''}
          `;

  const breadcrumbs = [
    {
      path: PathsE.HOME,
      text: t('general.home'),
    },
    {
      path: PathsE.BOTS,
      text: t('bots.bots'),
    },
  ];

  if (isLoading || !data) return <Loader />;

  return (
    <>
      <Box>
        <PageTitle
          title={`
          ${data.name ? data.name + ' ' : ''}
          ${data.username ? data.username + ' ' : ''}
          `}
          breadcrumbs={breadcrumbs}
          backUrl={{
            path: PathsE.BOTS,
          }}
        />
        <Card mb="20px" borderRadius="20px" px="30px">
          <Flex justifyContent="space-between" alignItems="center" mt="-10px">
            <Text color={gray700} fontWeight="600">
              {renderName()}
            </Text>
            <Flex alignItems="center">
              <Box mr="10px">
                <Link<PathsParams[PathsE.UPDATE_BOT]>
                  path={PathsE.UPDATE_BOT}
                  pathsParams={{ botId }}
                >
                  <Button
                    color={gray800}
                    bgColor={gray100}
                    borderRadius="8px"
                    leftIcon={<MatIcon name="edit" />}
                  >
                    {t('bots.editBot')}
                  </Button>
                </Link>
              </Box>
              <MainItemMenu
                isModelView
                data={data}
                handleActivate={handleActivate}
                handleDeactivate={handleDeactivate}
                viewLink={PathsE.SHOW_BOT}
                pathsParams={{ botId }}
              >
                <MenuItem
                  _hover={{
                    backgroundColor: gray100,
                  }}
                  onClick={handleDelete}
                >
                  <MatIcon name="delete" />
                  <Text color={gray700} ml="8px" fontSize="sm">{t('bots.deleteBot')}</Text>
                </MenuItem>
              </MainItemMenu>
            </Flex>
          </Flex>
          <MainModelInfo data={data} />
          <BotInfo data={data} />
        </Card>
      </Box>
      <DeleteModal
        title={t('bots.deleteBot')}
        text={t('general.approveTo', { phrase: t('bots.deleteBot') })}
        onClose={onClose}
        isOpen={isOpen}
        onSubmit={() => deleteMutate({})}
      />
    </>
  );
};

export default ViewBot;
