import { yupResolver } from '@hookform/resolvers/yup';
import { Resolver } from 'react-hook-form';


import * as Yup from 'yup';
import initDefaultSchema from '../yupConfig';
import {ResourceTypeT} from '../api/types/subscribers'
import {SignInQrCodeT} from '../api/types/managers'

initDefaultSchema();

export const sourceType: ResourceTypeT[] = ['channel', 'bot'];

export const sourceTypeOptions = sourceType.map((type) => ({
  value: type,
  label: type,
}));

export type SignInQrCodeFormT = SignInQrCodeT['request'];

export const getSignInQrCodeValues = (): SignInQrCodeFormT => ({
  apiId: 6,
  apiHash: 'eb06d4abfb49dc3eeb1aeb98ae0f581e',
  sourceId: '',
  sourceType: 'channel',
  isActive: true,
  isDeleted: true
});

const SignInQrCodeFormSchema: Yup.ObjectSchema<SignInQrCodeFormT> = Yup.object({
  apiId: Yup.number().required(),
  apiHash: Yup.string().required(),
  sourceId: Yup.string().required(),
  sourceType: Yup.string().oneOf(sourceType).required(),
  isActive: Yup.boolean().required(),
  isDeleted: Yup.boolean().required(),
});

export const signInQrCodeFormResolver = yupResolver(SignInQrCodeFormSchema) as Resolver<SignInQrCodeFormT>;
