import {
  Box,
  Button,
  Flex,
  MenuItem,
  Text,
  useDisclosure
} from '@chakra-ui/react';
import MatIcon from 'components/MatIcon';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  useActivateOneSubscriber, useDeactivateOneSubscriber,
  useDeleteSubscriber, useGetOneSubscriber
} from 'shared/api/requests/subscribers';
import Card from 'shared/components/horizon-ui/card/Card';
import Link from 'shared/components/Link';
import Loader from 'shared/components/Loader';
import MainItemMenu from 'shared/components/MainItemMenu';
import MainModelInfo from 'shared/components/MainModelInfo';
import {
  CreateSubscriberFormT,
  createSubscriberResolver,
  getCreateSubscriberValues
} from 'shared/form-values/subscribers';
import useGetColors from 'shared/hooks/useGetColors';
import { PathsE, PathsParams } from 'shared/routes';
import DeleteModal from '../admin/DeleteModal';
import PageTitle from '../admin/PageTitle';
import SubscriberInfo from './SubscriberInfo';
import { HSeparator } from 'shared/components/horizon-ui/separator/Separator';
import SubscriberMessages from './SubscriberMessages';

const ViewSubscriber = () => {
  const { t } = useTranslation();
  const { subscriberId } = useParams<PathsParams[PathsE.SHOW_SUBSCRIBER]>();
  const {
    gray800,
    gray700,
    gray100,
  } = useGetColors();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const requestParams = {
    pathParams: { subscriberId },
    params: {},
  };
  const { mutate: activateMutation } = useActivateOneSubscriber();
  const { mutate: deactivateMutation } = useDeactivateOneSubscriber();
  const handleActivate = (subscriberId: string) => activateMutation({ subscriberId });
  const handleDeactivate = (subscriberId: string) => deactivateMutation({ subscriberId });

  const { mutate: deleteMutate } = useDeleteSubscriber(requestParams);
  const { data, isLoading } = useGetOneSubscriber(requestParams);
  const methods = useForm<CreateSubscriberFormT>({
    defaultValues: getCreateSubscriberValues(data),
    resolver: createSubscriberResolver,
  });

  const handleDelete = () => onOpen();

  useEffect(() => {
    methods.reset(getCreateSubscriberValues(data));
  }, [data]);

  const renderName = () => `
          ${data.name ? data.name + ' ' : ''}
          ${data.username ? data.username + ' ' : ''}
          `;

  const breadcrumbs = [
    {
      path: PathsE.HOME,
      text: t('general.home'),
    },
    {
      path: PathsE.SUBSCRIBERS,
      text: t('subscribers.subscribers'),
    },
  ];

  if (isLoading || !data) return <Loader />;

  return (
    <>
      <Box>
        <PageTitle
          title={`
          ${data.name ? data.name + ' ' : ''}
          ${data.username ? data.username + ' ' : ''}
          `}
          breadcrumbs={breadcrumbs}
          backUrl={{
            path: PathsE.SUBSCRIBERS,
          }}
        />
        <Card mb="20px" borderRadius="20px" px="30px">
          <Flex justifyContent="space-between" alignItems="center" mt="-10px">
            <Text color={gray700} fontWeight="600">
              {renderName()}
            </Text>
            <Flex alignItems="center">
              <Box mr="10px">
                <Link<PathsParams[PathsE.UPDATE_SUBSCRIBER]>
                  path={PathsE.UPDATE_SUBSCRIBER}
                  pathsParams={{ subscriberId }}
                >
                  <Button
                    color={gray800}
                    bgColor={gray100}
                    borderRadius="8px"
                    w="100px"
                    leftIcon={<MatIcon name="edit" />}
                  >
                    Edit
                  </Button>
                </Link>
              </Box>
              <MainItemMenu
                isModelView
                data={data}
                handleActivate={handleActivate}
                handleDeactivate={handleDeactivate}
                viewLink={PathsE.SHOW_SUBSCRIBER}
                pathsParams={{ subscriberId }}
              >
                <MenuItem
                  _hover={{
                    backgroundColor: gray100,
                  }}
                  onClick={handleDelete}
                >
                  <MatIcon name="delete" />
                  <Text color={gray700} ml="8px" fontSize="sm">Delete</Text>
                </MenuItem>
              </MainItemMenu>
            </Flex>
          </Flex>
          <FormProvider {...methods}>
            <MainModelInfo data={data} />
            <SubscriberInfo data={data} />
            <HSeparator my="20px" mx="-20px" width="calc(100% + 40px)" />
            <SubscriberMessages messages={data.messages} />
          </FormProvider>
        </Card>
      </Box>
      <DeleteModal
        title="Delete subscriber?"
        text="Do you want to delete user?"
        onClose={onClose}
        isOpen={isOpen}
        onSubmit={() => deleteMutate({})}
      />
    </>
  );
};

export default ViewSubscriber;
