import { Button, Flex, IconButton, useDisclosure, Text } from "@chakra-ui/react";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import MatIcon from "components/MatIcon";
import { useMemo, useState } from "react";
import { TemplateFormValuesT, TemplateT } from "shared/api/types/channels";
import Table from "shared/components/table/Table";
import useGetColors from "shared/hooks/useGetColors";
import { formatDateToHuman } from "shared/utils/formattings";
import DeleteModal from "../admin/DeleteModal";
import { useTranslation } from "react-i18next";
import { useCreateTemplate, useDeleteTemplate, useUpdateTemplate } from "shared/api/requests/channels";
import { useParams } from "react-router-dom";
import { PathsParams, PathsE } from "shared/routes";
import TemplateModal from "./TemplateModal";
import { CreateTemplateFormT } from "shared/form-values/channels";

interface Props {
  values: TemplateT[],
}

const ChannelTemplates = ({ values }: Props) => {
  const { t } = useTranslation();
  const { channelId } = useParams<PathsParams[PathsE.SHOW_CHANNEL]>();
  const columnHelper = createColumnHelper<TemplateT>();
  const { brand500 } = useGetColors();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isOpenTemplateModal, onOpen: onOpenTemplateModal, onClose: onCloseTemplateModal } = useDisclosure();
  const [selectedTemplate, setSelectedTemplate] = useState<string | null>(null);

  const { mutate: updateMutation } = useUpdateTemplate({
    pathParams: { channelId, templateId: selectedTemplate! },
    params: undefined
  });

  const { mutate: createMutation } = useCreateTemplate({
    pathParams: { channelId },
    params: undefined
  });

  const { mutate: deleteMutation } = useDeleteTemplate({
    pathParams: { templateId: selectedTemplate!, channelId },
    params: undefined
  });

  const columns = useMemo(
    () => [
      columnHelper.accessor('type', {
        header: t('channels.table.type'),
        id: 'type',
      }),
      columnHelper.accessor('afterTime', {
        header: t('channels.table.afterTime'),
        id: 'afterTime',
        meta: {
          isNumeric: true,
        }
      }),
      columnHelper.accessor('message', {
        header: t('channels.table.message'),
        id: 'message',
      }),
      columnHelper.accessor('createdAt', {
        header: t('subscribers.table.createdAt'),
        id: 'createdAt',
        cell: ({ getValue }) => formatDateToHuman(getValue(), false),
        meta: {
          isNumeric: true,
          styles: {
            sizes: {
              width: '120px'
            }
          }
        }
      }),
      columnHelper.accessor('id', {
        header: '',
        id: 'actions',
        cell: ({ getValue }) => (
          <Flex alignItems="center">
            <IconButton aria-label="edit-icon" icon={<MatIcon name="edit" color={brand500} />} onClick={() => {
              setSelectedTemplate(getValue());
              onOpenTemplateModal();
            }} />
            <IconButton aria-label="delete-icon" icon={<MatIcon name="delete" color="#bb2124" />} onClick={() => {
              setSelectedTemplate(getValue());
              onOpen();
            }} />
          </Flex>
        ),
        meta: {
          styles: {
            sizes: {
              width: '100px'
            }
          }
        }
      }),
    ],
    [t]
  );

  const handleCloseTemplateModal = () => {
    onCloseTemplateModal();
    setSelectedTemplate(null)
  }

  const formatRequestData = (data: CreateTemplateFormT): TemplateFormValuesT => ({
    ...data,
    buttons: data.buttons,
    afterTime: Number(data.afterTime),
  })

  return (
    <>
      <Table data={values} columns={columns as ColumnDef<TemplateT>[]} />
      <DeleteModal
        title={t('channels.deletePush')}
        text={t('general.approveTo', { phrase: t('channels.deletePush') })}
        onClose={onClose}
        isOpen={isOpen}
        onSubmit={() => deleteMutation({}, { onSuccess: () => setSelectedTemplate(null) })}
      />
      <TemplateModal
        title={selectedTemplate ? t('channels.updatePush') : t('channels.push')}
        onClose={handleCloseTemplateModal}
        template={values.find(template => template.id === selectedTemplate)}
        isOpen={isOpenTemplateModal}
        buttonText={selectedTemplate ? t('buttons.update') : t('buttons.create')}
        onSubmit={(data: CreateTemplateFormT) => {
          if (!selectedTemplate) {
            createMutation([formatRequestData(data)], { onSuccess: handleCloseTemplateModal });
            return;
          }
          updateMutation(
            formatRequestData(data), { onSuccess: handleCloseTemplateModal });
        }}
      />
      <Flex justifyContent="end">
        <Button
          mt="16px"
          fontSize="16px"
          color="white"
          backgroundColor="brand.500"
          fontWeight="600"
          _hover={{
            backgroundColor: "brand.400"
          }}
          paddingY="8px"
          display="flex"
          borderRadius="8px"
          alignItems="center"
          onClick={onOpenTemplateModal}
        >
          <MatIcon name="add" size={24} color="gray800" variant="rounded" />
          <Text ml="8px">{t('channels.push')}</Text>
        </Button>
      </Flex>
    </>
  )
}

export default ChannelTemplates;
