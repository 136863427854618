import React, { CSSProperties, useEffect, useState } from 'react'
import useGetColors from 'shared/hooks/useGetColors';
import useHandleCopy from 'shared/hooks/useHandleCopy';
import { Box, Flex, Grid, IconButton, Tag, Text, TextProps, Tooltip } from '@chakra-ui/react';
import CutText from './CutText';
import { HSeparator } from './horizon-ui/separator/Separator';
import { Link } from 'react-router-dom';
import MatIcon from 'components/MatIcon';

type CellType = 'text' | 'copy-text' | 'link' | 'activation-status' | 'deletion-status';

type CommonCellT = {
  label: string,
  type: CellType,
  styles?: TextProps,
  style?: CSSProperties,
};

export type InfoBoxesT = Cell[];

type Cell<T extends CellType = CellType> = Modify<CommonCellT, T extends 'copy-text'
  ? { value: { label: string | number, valueToCopy: string | number } }
  : T extends 'link'
  ? { link: string, linkLabel: string }
  : T extends 'activation-status' | 'deletion-status'
  ? { status: boolean }
  : { value: string | number }>;


interface Props {
  infoBoxes: InfoBoxesT,
}

const ModelInfo = ({ infoBoxes }: Props) => {
  const handleCopy = useHandleCopy();
  const [isLabelOpen, setIsLabelOpen] = useState(false);
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>(null);

  const {
    blue800,
    gray500,
    blue500,
    green100,
    red100,
    green800,
    red800,
  } = useGetColors();

  useEffect(() => {
    return () => clearTimeout(timeoutId);
  }, [timeoutId])
  
  const renderCellComponent = (cell: Cell) => {
    switch (cell.type) {
      case 'text': return (
        <Text fontSize="14px" color={blue800} fontWeight="700" {...cell.styles} style={cell.style}>
          {(cell as Cell<'text'>).value}
        </Text>
      )

      case 'link': return (
        <Link to={(cell as Cell<'link'>).link}>
          <Text textDecoration="underline" fontWeight="500" color={blue500} fontSize="14px" {...cell.styles} style={cell.style}>
            {(cell as Cell<'link'>).linkLabel}
          </Text>
        </Link>
      )

      case 'activation-status': return (
        <Tag
          textTransform="uppercase"
          fontWeight="700"
          backgroundColor={(cell as Cell<'activation-status'>).status ? green100 : red100}
          color={(cell as Cell<'activation-status'>).status ? green800 : red800}
          fontSize="12px"
          {...cell.styles} style={cell.style}
        >
          {(cell as Cell<'activation-status'>).status ? 'Active' : 'Inactive'}
        </Tag>
      )

      case 'deletion-status': return (
        <Tag
          textTransform="uppercase"
          fontWeight="700"
          backgroundColor={(cell as Cell<'deletion-status'>).status ? red100 : green100}
          color={(cell as Cell<'deletion-status'>).status ? red800 : green800}
          fontSize="12px"
          {...cell.styles} style={cell.style}
        >
          {(cell as Cell<'deletion-status'>).status ? 'Deleted' : 'Not Deleted'}
        </Tag>
      )

      case 'copy-text': return (
        <Flex>
          <CutText fontSize="14px" w="90px" color={blue800} fontWeight="700" {...cell.styles} style={cell.style}>
            {(cell as Cell<'copy-text'>).value.label}
          </CutText>
          <Tooltip hasArrow isOpen={isLabelOpen} label='Successfully copied' placement='top'>
            <IconButton
              w="20px"
              h="20px"
              ml="4px"
              minW="unset"
              borderRadius="2px"
              onClick={() => {
                handleCopy((cell as Cell<'copy-text'>).value.valueToCopy.toString())
                setIsLabelOpen(true);
                const id = setTimeout(() => setIsLabelOpen(false), 1000);
                setTimeoutId(id);
              }}
              icon={<MatIcon name="content_copy" />}
              aria-label="copy"
            />
          </Tooltip>
        </Flex>
      )

      default: return null;
    }
  }

  return (
    <Box>
      <HSeparator my="20px" mx="-20px" width="calc(100% + 40px)" />
      <Grid
        gridTemplateColumns={{
          lg: '1fr 1fr 1fr 1fr',
          md: '1fr 1fr 1fr',
          base: 'minmax(0, 1fr) minmax(0, 1fr)',
        }}
        gridGap="10px"
        gridRowGap="20px"
      >
        {infoBoxes.map((cell, index) => (
          <Box key={index}>
            <Text
              color={gray500}
              fontSize="12px"
              mb="2px"
              fontWeight="600"
              textTransform="uppercase"
            >
              {cell.label}
            </Text>
            {renderCellComponent(cell)}
          </Box>
        ))}
      </Grid>
    </Box>
  )
}

export default ModelInfo;
