// Chakra Imports
import {
	Avatar,
	Box,
	Flex,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Text, useColorModeValue
} from '@chakra-ui/react';
// Custom Components
import PropTypes from 'prop-types';
import { SidebarResponsive } from 'shared/components/horizon-ui/sidebar/Sidebar';
// Assets
import useGetAllRoutes from 'shared/hooks/useGetAllRoutes';
import { useAuthContext } from 'shared/contexts/AuthContext';
import { useTranslation } from 'react-i18next';
export default function HeaderLinks(props: { secondary: boolean }) {
	const { t } = useTranslation();
	const { signOut, self } = useAuthContext();
	const { secondary } = props;
	const routes = useGetAllRoutes(true);

	// Chakra Color Mode
	const menuBg = useColorModeValue('white', 'navy.800');
	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const borderColor = useColorModeValue('#E6ECFA', 'rgba(135, 140, 189, 0.3)');
	const shadow = useColorModeValue(
		'14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
		'14px 17px 40px 4px rgba(112, 144, 176, 0.06)'
	);

	if (!self) return;

	return (
		<Flex
			w={{ sm: '100%', md: 'auto' }}
			alignItems='center'
			flexDirection='row'
			flexWrap={secondary ? { base: 'wrap', md: 'nowrap' } : 'unset'}
		>
			<SidebarResponsive routes={routes} />
			<Menu>
				<MenuButton p='0px'>
					<Avatar
						_hover={{ cursor: 'pointer' }}
						color='white'
						name={self.name}
						bg="gray.500"
						size='sm'
						w='32px'
						h='32px'
					/>
				</MenuButton>
				<MenuList boxShadow={shadow} p='0px' borderRadius='12px' bg={menuBg} border='none'>
					<Box
						ps='20px'
						pt='16px'
						pb='10px'
						w='100%'
						borderBottom='1px solid'
						borderColor={borderColor}
						fontSize='sm'
						fontWeight='700'
						color={textColor}
					>
						<Text>
							👋&nbsp;
							{t('profile.hey', { name: self.name })}
						</Text>
						<Text>
							{self.email}
						</Text>
					</Box>
					<Flex flexDirection='column' p='10px'>
						<MenuItem
							_hover={{ bg: 'none' }}
							_focus={{ bg: 'none' }}
							color='red.400'
							borderRadius='8px'
							onClick={() => signOut()}
							px='14px'>
							<Text fontSize='sm'>{t('profile.logOut')}</Text>
						</MenuItem>
					</Flex>
				</MenuList>
			</Menu>
		</Flex>
	);
}

HeaderLinks.propTypes = {
	variant: PropTypes.string,
	fixed: PropTypes.bool,
	secondary: PropTypes.bool,
	onOpen: PropTypes.func
};
