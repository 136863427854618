import { useQueryClient } from '@tanstack/react-query';
import React, {
  createContext,
  useContext, useEffect, useMemo, useState
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AUTHORIZATION_COOKIE, OTP_CODE_EXPIRATION_TIME_COOKIE, REFRESH_COOKIE, SESSION_ID } from 'shared/utils/constants';
import { ME_KEY, useGetMe, useLogout } from '../api/requests/auth';
import { AuthTokensT, SelfT } from '../api/types/auth';
import Loader from '../components/Loader';
import useToastes from '../hooks/useToastes';
import { LayoutsE, PathsE } from '../routes';
import { removeCookies, setCookies } from '../utils/devUtils';

type ContextType = {
  self: null | SelfT['response'],
  signIn: (data: AuthTokensT) => void,
  signOut: VoidFunction,
}

const initialState: ContextType = {
  self: null,
  signIn: () => true,
  signOut: () => null,
};

const AuthContext = createContext<ContextType>(initialState);

interface Props {
  children: React.ReactNode;
}

export const AuthContextProvider = ({ children }: Props) => {
  const queryClient = useQueryClient();
  const [self, setSelf] = useState<ContextType['self']>(null);
  const [targetLocation, setTargetLocation] = useState<ReturnType<typeof useLocation> | null>(null);
  const { data, isSuccess, isFetching } = useGetMe();
  const useLogoutMutation = useLogout();
  const navigate = useNavigate();
  const location = useLocation();
  const { successToast } = useToastes();

  useEffect(() => {
    if (!data) return;
    if (isSuccess) {
      setSelf(data);
      if (targetLocation && targetLocation.pathname !== '/' && !targetLocation.pathname.includes(LayoutsE.AUTH)) {
        navigate(targetLocation, { replace: true });
      } else {
        navigate(`/${LayoutsE.DASHBOARD}/${PathsE.SUBSCRIBERS}`, { replace: true });
      }
    }
  }, [data, isSuccess, isFetching]);

  const signIn = ({ accessToken, refreshToken }: AuthTokensT) => {
    successToast('You have successfully logged in');
    setCookies(AUTHORIZATION_COOKIE, accessToken);
    setCookies(REFRESH_COOKIE, refreshToken);
    removeCookies(SESSION_ID);
    removeCookies(OTP_CODE_EXPIRATION_TIME_COOKIE);
    // navigate(location);
    queryClient.invalidateQueries({ queryKey: [ME_KEY] });
  };

  const signOut = () => {
    useLogoutMutation.mutate({}, {
      onSuccess: () => {
        setSelf(null);
        setTargetLocation(null);
        removeCookies(AUTHORIZATION_COOKIE);
        removeCookies(REFRESH_COOKIE);
        navigate(LayoutsE.AUTH, { replace: true });
      },
    });
  };

  useEffect(() => {
    if (targetLocation) return;
    setTargetLocation(location);
  }, []);

  const value = useMemo(
    () => ({
      self,
      signIn,
      signOut,
    }),
    [signIn, signOut, self],
  );

  return (
    <AuthContext.Provider value={value}>
      {isFetching ? <Loader isAbsolute /> : children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => useContext(AuthContext);
