import { yupResolver } from '@hookform/resolvers/yup';
import { Resolver } from 'react-hook-form';
import * as Yup from 'yup';
import {
  ActivateT, ConfirmT, ForgotPasswordT, LoginT, SignupT,
} from '../api/types/auth'
import {
  REACT_APP_PASSWORD,
  REACT_APP_USER,
  IS_LOCAL_ENV,
  REACT_APP_USER_NAME,
  REACT_APP_USER_SURNAME
} from '../utils/constants'
import initDefaultSchema, { PASSWORD_REGEX } from '../yupConfig';
import {useTranslation} from 'react-i18next'

initDefaultSchema();

export type LoginFormT = LoginT['request'];
export type SignupFormT = SignupT['request'];
export type TwoFactorFormT = Pick<ConfirmT['request'], 'otpCode'>;
export type SetPasswordFormT = Prettify<ActivateT['request'] & {
  passwordConfirmation: string,
}>;
export type ForgotPasswordFormT = ForgotPasswordT['request'];

// Signup
export const useSignupSchema = () => {
  const { t } = useTranslation();

  const signupValues: SignupFormT = {
    name: IS_LOCAL_ENV ? REACT_APP_USER_NAME : '',
    surname: IS_LOCAL_ENV ? REACT_APP_USER_SURNAME : '',
    email: IS_LOCAL_ENV ? REACT_APP_USER : '',
    password: IS_LOCAL_ENV ? REACT_APP_PASSWORD : '',
  };

  const signupSchema: Yup.ObjectSchema<SignupFormT> = Yup.object({
    name: Yup.string().required(t('validation.required', { field: t('auth.name') })),
    surname: Yup.string().required(t('validation.required', { field: t('auth.surname') })),
    email: Yup.string().email(t('validation.email')).required(t('validation.required', { field: t('auth.email') })),
    password: Yup.string().required(t('validation.required', { field: t('auth.password') })),
  });

  return {
    signupValues,
    signupResolver: yupResolver(signupSchema) as Resolver<SignupFormT>,
  };
};

// Login
export const useLoginSchema = () => {
  const { t } = useTranslation();

  const loginValues: LoginFormT = {
    email: IS_LOCAL_ENV ? REACT_APP_USER : '',
    password: IS_LOCAL_ENV ? REACT_APP_PASSWORD : '',
  };

  const loginSchema: Yup.ObjectSchema<LoginFormT> = Yup.object({
    email: Yup.string()
      .email(t('validation.email'))
      .required(t('validation.required', { field: t('auth.email') })),
    password: Yup.string().required(t('validation.required', { field: t('auth.password') })),
  });

  return {
    loginValues,
    loginResolver: yupResolver(loginSchema) as Resolver<LoginFormT>,
  };
};

// Two factor
export const twoFactorValues: TwoFactorFormT = {
  otpCode: '',
};
const twoFactorSchema: Yup.ObjectSchema<TwoFactorFormT> = Yup.object({
  otpCode: Yup.string().required(),
});

export const twoFactorResolver = yupResolver(twoFactorSchema) as Resolver<TwoFactorFormT>;

// Set password
export const setPasswordValues: SetPasswordFormT = {
  password: '',
  passwordConfirmation: '',
};

const setPasswordSchema: Yup.ObjectSchema<SetPasswordFormT> = Yup.object({
  password: Yup.string().matches(PASSWORD_REGEX, 'Password should contain 8-24 characters, uppercase, lowercase and digits').required(),
  passwordConfirmation: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match'),
});

export const setPasswordResolver = yupResolver(setPasswordSchema) as Resolver<SetPasswordFormT>;

// Forgot password
export const forgotPasswordValues: ForgotPasswordFormT = {
  identifier: '',
};

const forgotPasswordSchema: Yup.ObjectSchema<ForgotPasswordFormT> = Yup.object({
  identifier: Yup.string().email().required('Email is a required field'),
});

export const forgotPasswordResolver = (
  yupResolver(forgotPasswordSchema) as Resolver<ForgotPasswordFormT>
);
