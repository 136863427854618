import React from 'react';
import { FormControl, FormLabel, Box } from '@chakra-ui/react';
import ReactPhoneField from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { Controller, useFormContext } from 'react-hook-form';
import useGetColors from '../../hooks/useGetColors';
import ErrorMessage from './ErrorMessage';
import useIsInvalid from './hooks/useIsInvalid';

export const inputStyles = {
  width: '100%',
  paddingLeft: '54px',
  paddingRight: '16px'
}

export const buttonStyles = {
  paddingLeft: '14px',
}

export const dropdownStyles = {
  borderRadius: '8px',
  boxShadow: '0px 4px 12px 0px #5E5E5E26',
  marginTop: '14px',
  left: '-4px',
  border: '1px solid #E2E8F0'
}

interface PhoneFieldProps {
  name: string;
  placeholder: string;
  label: string;
  defaultCountry?: string;
}

const PhoneField: React.FC<PhoneFieldProps> = ({
  name,
  label,
  defaultCountry = 'ua',
  placeholder,
  ...props
}) => {
  const { register } = useFormContext();
  const { control, formState: { errors, touchedFields } } = useFormContext();
  const isInvalid = useIsInvalid(name);
  const {red500, gray200} = useGetColors()

  console.log('isInvalid', isInvalid, errors, touchedFields);
  

  return (
    <FormControl isInvalid={isInvalid} mb="20px" mt="20px" {...props}>
      {label && (
        <FormLabel htmlFor={name} fontSize="md" fontWeight="500" mb="8px">
          {label}
        </FormLabel>
      )}
      <Controller
        name={name}
        control={control}
        render={({ field: { value, onChange } }) => (
          <Box
            className="phone-input-wrapper"
            sx={{
              '.react-tel-input': {
                width: '100%',
                border: '1px solid',
                borderColor: isInvalid ? red500 : gray200,
                borderRadius: 'md',
                padding: '4px',
                fontSize: 'md',
                height: '40px',
              },
              '.react-tel-input .form-control': {
                border: 'none',
                boxShadow: 'none',
                width: '100%',
                height: '100%',
                padding: '0',
                fontSize: 'inherit',
              },
              '.react-tel-input .flag-dropdown': {
                border: 'none',
                background: 'none',
                boxShadow: 'none',
              },
              '.react-tel-input .selected-flag': {
                padding: '0',
                marginRight: '8px',
              },
            }}
          >
            <ReactPhoneField
              {...register(name)}
              onChange={onChange}
              value={value}
              country={defaultCountry}
              inputProps={{
                name: name,
                required: true,
                autoFocus: false,
                placeholder: placeholder,
              }}
              enableSearch
              inputStyle={inputStyles}
              dropdownStyle={dropdownStyles}
              buttonStyle={buttonStyles}
            />
          </Box>
        )}
      />
      <ErrorMessage name={name} />
    </FormControl>
  );
};

export default PhoneField;
