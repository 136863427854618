import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import CutText from "shared/components/CutText";
import { LayoutsE, PathsE } from 'shared/routes'
import { FilterField } from "shared/types/filters";
import { formatDateToHuman } from "shared/utils/formattings";
import { ManagersElementT } from '../../api/types/managers';
import { Box, Flex, Link, Text } from '@chakra-ui/react'
import MatIcon from '../../../components/MatIcon';
import useGetColors from '../../hooks/useGetColors';
import { ResourceTypeT } from '../../api/types/subscribers';
import { useGetChannels } from '../../api/requests/channels';
import { useGetBots } from '../../api/requests/bots';
import { sourceTypeOptions } from '../../form-values/signInQrCode'
import { SourceType } from "shared/api/types/channels";

type SourceTypeStateT = [ResourceTypeT, React.Dispatch<React.SetStateAction<ResourceTypeT>>];
export const useGetFilterFields = ([sourceType, setSourceType]: SourceTypeStateT): FilterField[] => {
  const { t } = useTranslation();

  const filterFields = useMemo(() => {
    const fields: FilterField[] = [
      {
        type: 'text',
        name: 'name',
        defaultValue: '',
        placeholder: t('forms.enterName'),
        label: t('subscribers.table.name'),
        size: "lg",
      },
      {
        type: 'text',
        name: 'username',
        defaultValue: '',
        placeholder: t('forms.enterUsername'),
        label: t('subscribers.table.username'),
        size: "lg",
      },
      {
        type: 'select',
        name: 'sourceType',
        defaultValue: '',
        options: sourceTypeOptions,
        label: t('subscribers.table.source'),
        placeholder: t('forms.selectSource'),
        size: "lg",
        onChange: (sourceType: SourceType) => setSourceType(sourceType)
      },
    ];
    if (sourceType === 'channel') {
      const channelsField: FilterField = {
        type: 'async-select',
        name: 'sourceId',
        defaultValue: '',
        labelName: 'name',
        valueName: 'id',
        optionsQuery: useGetChannels,
        label: t('channels.table.sourceId'),
        placeholder: t('forms.selectSourceId'),
        size: "lg",
        fullWidth: true
      }
      fields.push(channelsField);
    } else if (sourceType === 'bot') {
      const botsField: FilterField = {
        type: 'async-select',
        name: 'sourceId',
        defaultValue: '',
        labelName: 'name',
        valueName: 'id',
        optionsQuery: useGetBots,
        label: t('channels.table.sourceId'),
        placeholder: t('forms.selectSourceId'),
        size: "lg",
        fullWidth: true
      };
      fields.push(botsField);
    }
    return fields;
  }, [t, sourceType]);
  
  return filterFields;
}

export const useGetGetBreadcrumbs = () => {
  const { t } = useTranslation();
  return [
    {
      path: PathsE.HOME,
      text: t('general.home'),
    },
  ];
}

export const useGetSignInBreadcrumbs = () => {
  const { t } = useTranslation();
  return [
    {
      path: PathsE.HOME,
      text: t('general.home'),
    },
    {
      path: PathsE.MANAGERS,
      text: t('managers.managers'),
    },
  ];
}

export const useGetCodeBreadcrumbs = () => {
  const { t } = useTranslation();
  return [
    {
      path: PathsE.HOME,
      text: t('general.home'),
    },
    {
      path: PathsE.MANAGERS,
      text: t('managers.managers'),
    },
    {
      path: PathsE.SIGNIN_SEND_CODE,
      text: t('managers.signInSendCode'),
    },
  ];
}

export const useGetColumns = () => {
  const { t } = useTranslation();
  const columnHelper = createColumnHelper<ManagersElementT>();
  const { brand500 } = useGetColors();

  return useMemo(
    () => [
      columnHelper.accessor('id', {
        header: t('subscribers.table.id'),
        id: 'id',
        cell: ({ getValue }) => (
          <CutText fontWeight="700">
            {getValue()}
          </CutText>
        ),
        meta: {
          isSorting: false,
          styles: {
            sizes: {
              width: '90px',
              maxW: '90px',
            }
          }
        }
      }),
      columnHelper.accessor('identifier', {
        header: t('subscribers.table.identifier'),
        id: 'identifier',
      }),
      columnHelper.accessor('name', {
        header: t('subscribers.table.name'),
        id: 'name',
      }),
      columnHelper.accessor('username', {
        header: t('subscribers.table.username'),
        id: 'username',
      }),
      columnHelper.accessor((row) => row.model.name ?? '', {
        header: t('subscriptions.table.resource'),
        id: 'resource',
        cell: ({ row, getValue }) => (
          <Link
            href={row.original.model.type === 'bot'
              ? `/${LayoutsE.DASHBOARD}/${PathsE.BOTS}/${row.original.model.id}`
              : `/${LayoutsE.DASHBOARD}/${PathsE.CHANNELS}/${row.original.model.id}`}
          >
            <Flex alignItems="center" justifyContent="space-between" gap={2}>
              <Box mr="4px">
                <MatIcon
                  name="group"
                  color={brand500}
                />
              </Box>
              <Text as="span" mr="4px">{getValue()}</Text>
            </Flex>
          </Link>
        ),
        meta: {
          styles: {
            sizes: {
              width: '150px'
            }
          }
        }
      }),
      columnHelper.accessor('createdAt', {
        header: t('subscribers.table.createdAt'),
        id: 'createdAt',
        cell: ({ getValue }) => formatDateToHuman(getValue(), false),
        meta: {
          isNumeric: true,
          styles: {
            sizes: {
              width: '120px'
            }
          }
        }
      }),
      columnHelper.accessor('updatedAt', {
        header: t('subscribers.table.updatedAt'),
        id: 'updatedAt',
        cell: ({ getValue }) => formatDateToHuman(getValue(), false),
        meta: {
          isNumeric: true,
          styles: {
            sizes: {
              width: '120px'
            }
          }
        }
      }),
      // columnHelper.accessor('id', {
      //   header: '',
      //   id: 'actions',
      //   cell: ({ row }) => (
      //     <MainItemMenu
      //       data={row.original}
      //       handleActivate={() => {}}
      //       handleDeactivate={() => {}}
      //       // handleActivate={handleActivate}
      //       // handleDeactivate={handleDeactivate}
      //       viewLink={PathsE.SHOW_CHANNEL}
      //       pathsParams={{ channelId: row.original.id }}
      //     />
      //   ),
      //   meta: {
      //     styles: {
      //       sizes: {
      //         width: '44px'
      //       }
      //     }
      //   }
      // }),
    ] as ColumnDef<ManagersElementT>[],
    [t]
  );
};

