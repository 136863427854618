// chakra imports
import { Flex, Stack } from '@chakra-ui/react';
import Logo from 'components/Logo';
//   Custom components
import Links from 'shared/components/horizon-ui/sidebar/components/Links';
import { RouteT } from 'shared/routes';
import { globalStyles } from 'theme/styles';

// FUNCTIONS

function SidebarContent(props: { routes: RouteT[] }) {
	const { routes } = props;
	// SIDEBAR
	return (
		<Flex direction='column' height='100%' pt='20px' borderRadius='30px'>
			<Logo isLong mb="20px" />
			<Stack direction='column' mb='auto' paddingTop="38px" gap="20px" px="16px" borderTop={`1px solid ${globalStyles.colors.secondaryGray[300]}`}>
        <Links routes={routes} />
			</Stack>
		</Flex>
	);
}

export default SidebarContent;
