import ModelInfo, { InfoBoxesT } from 'shared/components/ModelInfo';
import { OneLinkT } from '../../api/types/links';
import { useTranslation } from 'react-i18next';
import { LayoutsE, PathsE } from 'shared/routes';

interface Props {
  data: OneLinkT['response'];
}

const LinkInfo = ({ data }: Props) => {
  const { t } = useTranslation();
  const infoBoxes: InfoBoxesT = [
    {
      label: t('subscribers.table.name'),
      type: 'text',
      value: data.name,
    },
    {
      label: t('links.table.type'),
      type: 'text',
      value: data.type,
    },
    {
      label: t('links.link'),
      type: 'copy-text',
      value: {
        label: data.link,
        valueToCopy: data.link,
      },
    },
    {
      label: t('links.table.deepLink'),
      type: 'copy-text',
      value: {
        label: data.deepLink,
        valueToCopy: data.deepLink,
      },
    },
    {
      label: t('links.table.resource'),
      type: 'link',
      linkLabel: data.model.name,
      link: data.model.type === 'bot'
        ? `/${LayoutsE.DASHBOARD}/${PathsE.BOTS}/${data.model.id}`
        :`/${LayoutsE.DASHBOARD}/${PathsE.CHANNELS}/${data.model.id}`
    },
  ]

  return (
    <ModelInfo infoBoxes={infoBoxes} />
  );
};

export default LinkInfo;
