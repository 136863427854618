import { Box, Heading, Flex } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next';
import Card from 'shared/components/horizon-ui/card/Card';
import InfiniteTable from 'shared/components/table/InfiniteTable';
import useGetColors from 'shared/hooks/useGetColors';
import PageTitle from '../admin/PageTitle';
import { useGetColumns, useGetFilterFields, useGetGetBreadcrumbs } from './constants';
import { ManagersT } from '../../api/types/managers';
import { useGetManagers } from '../../api/requests/managers';
import AddManagerMenu from './AddManagerMenu';
import { ResourceTypeT } from 'shared/api/types/subscribers';
import { useState } from 'react';

const Index = () => {
  const { t } = useTranslation();
  const sourceTypeState = useState<ResourceTypeT>(null);
  const { blue800 } = useGetColors();
  const columns = useGetColumns();

  const breadcrumbs = useGetGetBreadcrumbs();
  const filterFields = useGetFilterFields(sourceTypeState);

  return (
    <Box>
      <PageTitle title={t('managers.managers')} breadcrumbs={breadcrumbs} />
      <Card mb="20px" borderRadius="20px" px="30px">
        <Flex alignItems="center">
          <Heading size="md" color={blue800}>
            {t('managers.managersTable')}
          </Heading>
          <AddManagerMenu />
        </Flex>
        <InfiniteTable<ManagersT>
          query={useGetManagers}
          columns={columns}
          filterFields={filterFields}
        />
      </Card>
    </Box>
  );
};

export default Index;
