import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { typedInfiniteQuery, typedMutation, typedQuery } from "shared/api/types/typedQueries";
import useToastes from 'shared/hooks/useToastes';
import useTypedHistory from 'shared/hooks/useTypedHistory';
import { PathsE } from 'shared/routes';
import {ManagersT, DeleteManagerT, OneManagerT, SignInQrCodeT, SignInSendCodeT, CodeT} from '../types/managers'
import { instance } from "./common";

const BASE_URL = 'managers'

const MANAGERS_KEY = 'MANAGERS_KEY';
const ONE_MANAGER_KEY = 'ONE_MANAGER_KEY';

export const useGetManagers = typedInfiniteQuery<ManagersT>(({ params }) => (
  useInfiniteQuery({
    queryKey: [MANAGERS_KEY, params],
    queryFn: () => instance.get<unknown, ManagersT['response']>(BASE_URL, { params }),
    initialPageParam: 1,
    getNextPageParam: () => null,
    getPreviousPageParam: () => null,
    // getNextPageParam: (lastPage, allPages, lastPageParam, allPageParams) => null,
    // getPreviousPageParam: (firstPage, allPages, firstPageParam, allPageParams) => null,
  })
));

export const useGetOneManager = typedQuery<OneManagerT>(({ pathParams: { managerId } }) => (
  useQuery({
    queryKey: [ONE_MANAGER_KEY, managerId],
    queryFn: () => instance.get(`${BASE_URL}/${managerId}`),
    enabled: !!managerId,
  })
));

export const useSignInQrCode = typedMutation<SignInQrCodeT>(() => {
  const queryCache = useQueryClient();
  const { successToast } = useToastes();
  const push = useTypedHistory();
  return (
    useMutation({
      mutationFn: (data) => instance.post(`${BASE_URL}/sign-in-qr-code`, data),
      onSuccess: () => {
        successToast('Manager was successfully created');
        push(PathsE.MANAGERS);
        queryCache.invalidateQueries({ queryKey: [MANAGERS_KEY] });
      }
    })
  )
});

export const useSignInSendCode = typedMutation<SignInSendCodeT>(() => {
  const queryCache = useQueryClient();
  const { successToast } = useToastes();
  return (
    useMutation({
      mutationFn: (data) => instance.post(`${BASE_URL}/send-code`, data),
      onSuccess: () => {
        successToast('Code was successfully sent, check your phone');
        queryCache.invalidateQueries({ queryKey: [MANAGERS_KEY] });
      }
    })
  )
});

export const useConfirmCode = typedMutation<CodeT>(() => {
  const queryCache = useQueryClient();
  const { successToast } = useToastes();
  const push = useTypedHistory();
  return (
    useMutation({
      mutationFn: (data) => instance.post(`${BASE_URL}/confirm-code`, data),
      onSuccess: () => {
        successToast('Code confirmed');
        push(PathsE.MANAGERS);
        queryCache.invalidateQueries({ queryKey: [MANAGERS_KEY] });
      }
    })
  )
});

export const useDeleteManager = typedMutation<DeleteManagerT>(({ pathParams: { managerId } }) => {
  const { successToast } = useToastes();
  const queryCache = useQueryClient()
  return (
    useMutation({
      mutationFn: () => instance.put(`${BASE_URL}/${managerId}`, { isDeleted: true }),
      onSuccess: () => {
        successToast('Manager was successfully deleted');
        queryCache.invalidateQueries({ queryKey: [MANAGERS_KEY] });
        queryCache.invalidateQueries({ queryKey: [ONE_MANAGER_KEY, managerId] });
      }
    })
  )
});
