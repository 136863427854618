import {
  FormControl,
  FormLabel,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputProps,
  NumberInputStepper,
} from '@chakra-ui/react';
import { FieldValues, useFormContext } from 'react-hook-form';
import { FieldProps } from 'shared/types/filters';
import ErrorMessage from './ErrorMessage';
import useIsInvalid from './hooks/useIsInvalid';

const NumberField = <TFieldValues extends FieldValues>({
  label,
  placeholder,
  name,
  size,
  min,
  max,
  step,
  ...props
}: FieldProps<'text'> & NumberInputProps) => {
  const { register } = useFormContext<TFieldValues>();
  const isInvalid = useIsInvalid(name);

  return (
    <FormControl {...props} size={size} position="relative" isInvalid={isInvalid}>
      {label && (
        <FormLabel fontSize="md" fontWeight="500" mb="8px">
          {label}
        </FormLabel>
      )}
      <NumberInput
        min={min}
        max={max}
        step={step}
      >
        <NumberInputField
          {...register(name)}
          placeholder={placeholder}
          autoComplete="off"
        />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>
      <ErrorMessage name={name} />
    </FormControl>
  );
};



export default NumberField;
