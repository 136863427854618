export const en = {
  "auth": {
    "email": "Email",
    "enterEmail": "Enter your email",
    "enterName": "Enter your name",
    "enterPassword": "Enter your password",
    "enterSurname": "Enter your surname",
    "forgotPassword": "Forgot password?",
    "name": "Name",
    "password": "Password",
    "signIn": "Sign In",
    "signUp": "Sign Up",
    "surname": "Surname"
  },
  "bots": {
    "bots": "Bots",
    "bot": "Bot",
    "botsTable": "Bots table",
    "createBot": "Create bot",
    "deleteBot": "Delete bot",
    "editBot": "Edit bot",
    "table": {
      "createdAt": "Created at",
      "id": "Id",
      "identifier": "Identifier",
      "name": "Name",
      "type": "Type",
      "updatedAt": "Updated at",
      "username": "Username"
    },
    "viewBot": "View bot"
  },
  "buttons": {
    "apply": "Apply",
    "cancel": "Cancel",
    "create": "Create",
    "update": "Update",
    "delete": "Delete",
    "edit": "Edit",
    "next": "Next",
    "search": "Search",
    "select": "Select"
  },
  "channels": {
    "addNewButton": "Add new button",
    "buttonText": "Button text",
    "buttonUrl": "Button url",
    "channels": "Channels",
    "channelsTable": "Channels table",
    "createChannel": "Create channel",
    "deleteChannel": "Delete channel",
    "deletePush": "Delete push",
    "editChannel": "Edit channel",
    "deleteBotAnswer": "Delete bot answer",
    "editBotAnswer": "Edit bot answer",
    "enterAfterTime": "Enter After Time",
    "enterMessage": "Enter Message",
    "enterText": "Enter Text",
    "enterUrl": "Enter Url",
    "selectButtonType": "Select Button Type",
    "selectTemplateId": "Select Template ID",
    "selectType": "Select Type",
    "table": {
      "afterTime": "After time",
      "createdAt": "Created at",
      "id": "Id",
      "identifier": "Identifier",
      "isActive": "Is Active",
      "message": "Message",
      "messageAt": "Message at",
      "name": "Name",
      "sourceId": "Source ID",
      "template": "Template",
      "type": "Type",
      "updatedAt": "Updated at"
    },
    "updatePush": "Update push",
    "viewChannel": "View channel",
    "push": "Push",
    "botAnswer": "Bot Answer",
    "botAnswerIsActive": "Bot answer is active",
    "botAnswerIsNotActive": "Bot answer is not active"
  },
  "dtos": {
    "link_one": "Link",
    "link_other": "Links"
  },
  "fields": {
    "channel_one": "Channel",
    "channel_other": "Channels"
  },
  "footer": {
    "allRightsReserved": "All rights reserved"
  },
  "forms": {
    "botId": "Bot Id",
    "buyer": "Buyer",
    "channelId": "Channel Id",
    "enterBuyer": "Enter Buyer",
    "enterFbPixel": "Enter fbPixel",
    "enterFbToken": "Enter fbToken",
    "enterSource": "Enter Source",
    "enterName": "Enter Name",
    "enterUsername": "Enter Username",
    "fbPixel": "fbPixel",
    "fbToken": "fbToken",
    "selectBotId": "Select Bot ID",
    "selectChannelId": "Select Channel ID",
    "source": "Source",
    "sourceId": "Source ID",
    "selectSourceId": "Select Source ID",
    "apiId": "API ID",
    "enterApiId": "Enter API ID",
    "apiHash": "API Hash",
    "enterApiHash": "Enter API Hash",
    "phoneNumber": "Phone number",
    "enterPhoneNumber": "Enter phone number",
    "code": "Code",
    "enterCode": "Enter code",
    "password": "Password",
    "enterPassword": "Enter Password",
    "selectChannels": "Select Channels",
    "selectBots": "Select Bots",
    "selectSubscribers": "Select Subscribers",
    "selectSource": "Select Source",
    "enterUrl": "Enter URL"
  },
  "general": {
    "administrator": "Administrator",
    "administrators": "Administrators",
    "approveTo": "Do you want to {{ phrase }}?",
    "createAdministrator": "Create Administrator",
    "editAdministrator": "Edit Administrator",
    "filters": "Filters",
    "home": "Home",
    "pageNotFound": "Page not found",
    "personalData": "Personal Data",
    "table": {
      "dtoTable": "{{ dtoName }} table",
      "noData": "No Data",
      "pagination": "Showing {{currCountOfRows}} to {{toCountOfRows}} of {{total}} entries"
    },
    "viewAdministrator": "View Administrator"
  },
  "links": {
    "contactDeveloper": "Contact Developer",
    "createBotsLink": "Create Bot's Link",
    "createChannelsLink": "Create Channel's Link",
    "botsLink": "Bot's Link",
    "channelsLink": "Channel's Link",
    "links": "Links",
    "support": "Support",
    "link": "Link",
    "viewLink": "View link",
    "table": {
      "deepLink": "Deep",
      "subscribedAt": "Subscribed At",
      "firstName": "First name",
      "type": "Type",
      "resource": "Resource",
    }
  },
  "profile": {
    "hey": "Hey, {{name}}",
    "logOut": "Log out"
  },
  "subscribers": {
    "createSubscriber": "Create subscriber",
    "editSubscriber": "Edit subscriber",
    "subscribers": "Subscribers",
    "subscribersTable": "Subscribers table",
    "table": {
      "bot": "Bot",
      "buyer": "Buyer",
      "createdAt": "Created at",
      "id": "Id",
      "identifier": "Identifier",
      "isSent": "Sent successfully",
      "message": "Message",
      "messages": "Messages",
      "name": "Name",
      "received": "Received",
      "resource": "Resource",
      "resources": "Resources",
      "sendAt": "Send at",
      "sent": "Sent",
      "source": "Source",
      "type": "Type",
      "updatedAt": "Updated at",
      "username": "Username"
    },
    "viewSubscriber": "View subscriber"
  },
  "subscriptions": {
    "createSubscription": "Create subscription",
    "editSubscription": "Edit subscription",
    "subscriptions": "Subscriptions",
    "subscriptionsTable": "Subscriptions table",
    "table": {
      "buyer": "Buyer",
      "createdAt": "Created at",
      "id": "Id",
      "identifier": "Identifier",
      "name": "Name",
      "resource": "Resource",
      "source": "Source",
      "updatedAt": "Updated at",
      "url": "Url",
      "username": "Username"
    },
    "viewSubscription": "View subscription"
  },
  "managers": {
    "manager": "Manager",
    "editManager": "Edit manager",
    "managers": "Managers",
    "managersTable": "Managers table",
    "viewManager": "View manager",
    "signInQrCode": "Sign In QR Code",
    "timeLeft": "Time left: {{timeLeft}} seconds",
    "attemptsLeft": "Attempt: {{attemptsLeft}} / 3",
    "timeExpired": "Time Expired, try again later",
    "signInSendCode": "Sign In Send Code",
    "signInSendCodeAndPassword": "Sign In Send Code & Password",
    "enterTgCode": "Check your Telegram and enter code below",
  },
  "validation": {
    "email": "Please enter a valid email address",
    "required": "{{field}} is a required field",
    "invalidPassword": "Please enter a valid password",
    "invalidCode": "Please enter a valid code"
  },
  "yes": "Yes",
  "no": "No"
};
