import { Box, Flex, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Card from 'shared/components/horizon-ui/card/Card';
import Loader from 'shared/components/Loader';
import MainModelInfo from 'shared/components/MainModelInfo';
import useGetColors from 'shared/hooks/useGetColors';
import { PathsE, PathsParams } from 'shared/routes';
import PageTitle from '../admin/PageTitle';
import {useGetOneLink} from '../../api/requests/links'
import LinkInfo from './LinkInfo'
import LinkSubscribers from './LinkSubscribers'

const ViewLink = () => {
  const { t } = useTranslation();
  const { linkId } = useParams<PathsParams[PathsE.SHOW_LINK]>();
  const { gray700 } = useGetColors();
  const requestParams = {
    pathParams: { linkId },
    params: {},
  };

  const { data, isLoading } = useGetOneLink(requestParams);

  const breadcrumbs = [
    {
      path: PathsE.HOME,
      text: t('general.home'),
    },
    {
      path: PathsE.LINKS,
      text: t('links.links'),
    },
  ];

  if (isLoading || !data) return <Loader />;

  return (
    <>
      <Box>
        <PageTitle
          title={data.name}
          breadcrumbs={breadcrumbs}
          backUrl={{
            path: PathsE.BOTS,
          }}
        />
        <Card mb="20px" borderRadius="20px" px="30px">
          <Flex justifyContent="space-between" alignItems="center" mt="-10px">
            <Text color={gray700} fontWeight="600">
              {data.name}
            </Text>
          </Flex>
          <MainModelInfo data={data} />
          <LinkInfo data={data} />
          <LinkSubscribers values={data.subscribers} />
        </Card>
      </Box>
    </>
  );
};

export default ViewLink;
