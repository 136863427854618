import { Text } from '@chakra-ui/react';
import { useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useLogin } from 'shared/api/requests/auth';
import PasswordField from 'shared/components/form/PasswordField';
import TextField from 'shared/components/form/TextField';
import Link from 'shared/components/Link';
import ShadowCard from 'shared/components/ShadowCard';
import SubmitButton from 'shared/components/SubmitButton';
import { LoginFormT, useLoginSchema } from 'shared/form-values/auth';
import useGetColors from 'shared/hooks/useGetColors';
import useTypedHistory from 'shared/hooks/useTypedHistory';
import { LayoutsE, PathsE } from 'shared/routes';
import { fillFormErrors, getCookies } from 'shared/utils/devUtils';
import { useTranslation } from 'react-i18next';

const Login = () => {
  const { t } = useTranslation();
  const { loginValues, loginResolver } = useLoginSchema();
  const otpCodeExpirationTimeFromCookie = getCookies('otpCodeExpirationTime');
  const otpCodeExpirationTime = otpCodeExpirationTimeFromCookie - new Date().getTime();
  const push = useTypedHistory();
  const { mutate, isPending } = useLogin();
  const methods = useForm<LoginFormT>({
    defaultValues: loginValues,
    resolver: loginResolver,
    mode: "onChange"
  });

  useEffect(() => {
    methods.trigger();
  }, []);

  const { textColorBrand } = useGetColors();
  const onSubmit: SubmitHandler<LoginFormT> = (values) => mutate(values, { onError: (error) => fillFormErrors<LoginFormT>(methods.setError, error, methods.getValues()) });

  useEffect(() => {
    if (otpCodeExpirationTime > 0) push(PathsE.TWO_FA, LayoutsE.AUTH);
  }, [otpCodeExpirationTime])

  return (
    <ShadowCard titleText={t('auth.signIn')}>
      <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <TextField<LoginFormT>
          name="email"
          type="email"
          label={t('auth.email')}
          placeholder={t('auth.enterEmail')}
          mb="24px"
        />
        <PasswordField<LoginFormT> label={t('auth.password')} placeholder={t('auth.enterPassword')} />
        <SubmitButton label={t('buttons.next')} mt="40px" mb="24px" isLoading={isPending} />
        <Link layout={LayoutsE.AUTH} path={PathsE.SIGN_UP}>
          <Text
            color={textColorBrand}
            fontSize="md"
            fontWeight="500"
            textAlign="center"
            mb="16px"
          >
            {t('auth.signUp')}
          </Text>
        </Link>
        {/* <Link layout={LayoutsE.AUTH} path={PathsE.FORGOT_PASSWORD}>
          <Text
            color={gray800}
            fontSize="sm"
            fontWeight="500"
            textAlign="center"
          >
            {t('auth.forgotPassword')}
          </Text>
        </Link> */}
      </form>
      </FormProvider>
    </ShadowCard>
  );
};

export default Login;
