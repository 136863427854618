import { Box, IconButton, Table as ChakraTable, useDisclosure } from '@chakra-ui/react';
import { InfiniteData } from '@tanstack/query-core';
import {
  ColumnDef,
  getCoreRowModel,
  useReactTable
} from '@tanstack/react-table';
import { ListQueryRequestType, QueryOptionsT } from 'shared/api/types/typedQueries';
import { FilterField } from 'shared/types/filters';
import MatIcon from '../../../components/MatIcon';
import { InfiniteQueryResult } from '../../api/types/typedQueries';
import useGetColors from '../../hooks/useGetColors';
import { SearchBar } from '../horizon-ui/navbar/searchBar/SearchBar';
import Pagination from '../Pagination';
import FiltersDrawer from './FilterDrawer';
import useGetSortingMethods from './hooks/useGetSortingMethods';
import TableBody from './TableBody';
import TableFooter from './TableFooter';
import TableHead from './TableHead';
import {useMemo} from 'react';
import {useForm} from 'react-hook-form';

interface Props<QueryData extends ListQueryRequestType> {
  columns: ColumnDef<ArrayElement<QueryData['response']['data']>>[];
  query: (
    params?: Pick<QueryData, 'pathParams' | 'params'>,
    options?: QueryOptionsT
  ) => InfiniteQueryResult<InfiniteData<QueryData['response']>>;
  clickable?: boolean;
  filterFields?: FilterField[];
}

const InfiniteTable = <QueryData extends ListQueryRequestType>({
  query,
  columns,
  clickable,
  filterFields,
}: Props<QueryData>) => {
  type TData = ArrayElement<QueryData['response']['data']>;
  const {
    data,
    filters,
    isLoading,
    order,
    page,
    size,
    sortBy,
    total,
    changeOrder,
    changePage,
    search,
    changeSearch,
    changeSize,
    changeSortBy,
    clearFilter,
    handleFilter,
  } = useGetSortingMethods<QueryData>({ filterFields, query });
  const { red500, blue500 } = useGetColors();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const methods = useForm({ defaultValues: filters as UnknownRecord });

  const table = useReactTable<TData>({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    debugTable: true,
    meta: {},
  });

  const handleResetFilters = () => {
    clearFilter();
    methods.reset();
  };

  const areFiltersActive = useMemo(() => {
    return !!search || Object.values(filters).some(item => {
      if (Array.isArray(item)) return !!item.length;
      return !!item;
    })
  }, [filters, search]);

  return (
    <div>
      <Box overflowX="auto" mt={{ sm: 2, md: 25 }}>
        <Box
          display="flex" gap={{ sm: 3, md: 6 }}
          position={{ sm: 'unset', md: 'absolute' }} top={5} right={5}
        >
          <SearchBar searchValue={search} handleChangeSearch={changeSearch} />
          <IconButton aria-label="Filters" onClick={onOpen} borderRadius="10px" backgroundColor="secondaryGray.300" icon={<MatIcon name="filter_list" color={blue500} />} />
          {areFiltersActive && (
            <IconButton
              aria-label="Reset filters"
              onClick={handleResetFilters}
              borderRadius="10px"
              backgroundColor="secondaryGray.300"
              icon={<MatIcon name="filter_list_off" color={red500} />}
            />
          )}
        </Box>
        <ChakraTable
          size="sm"
          w="100%"
          // style={{ tableLayout: 'fixed' }}
          minWidth="max-content"
          width="-webkit-fill-available"
        >
          <TableHead<TData>
            table={table}
            setOrder={changeOrder}
            setSortBy={changeSortBy}
            order={order}
            sortBy={sortBy}
          />
          <TableBody<TData> table={table} data={data} isLoading={isLoading} clickable={clickable} size={size} />
          <TableFooter<TData> table={table} />
        </ChakraTable>
      </Box>
      <Pagination
        setSize={changeSize}
        setPage={changePage}
        page={page}
        size={size}
        total={total}
      />
      <FiltersDrawer
        isOpen={isOpen}
        onClose={onClose}
        handleFilter={handleFilter}
        values={filters}
        fields={filterFields}
        methods={methods}
      />
    </div>
  );
};

export default InfiniteTable;
