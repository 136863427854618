import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { typedInfiniteQuery, typedMutation, typedQuery } from 'shared/api/types/typedQueries';
import { CreateBotsLinkT, CreateChannelsLinkT, LinksT, OneLinkT } from '../types/links';
import { instance } from "./common";
import useToastes from '../../hooks/useToastes';
import useTypedHistory from '../../hooks/useTypedHistory';
import { PathsE } from '../../routes';

const BASE_URL = 'links'

const LINKS_KEY = 'LINKS_KEY';
const ONE_LINK_KEY = 'ONE_LINK_KEY';

export const useGetLinks = typedInfiniteQuery<LinksT>(({ params }) => (
  useInfiniteQuery({
    queryKey: [LINKS_KEY, params],
    queryFn: () => instance.get<LinksT['response']>(BASE_URL, { params }),
    initialPageParam: 1,
    getNextPageParam: () => null,
    getPreviousPageParam: () => null,
  })
));

export const useGetOneLink = typedQuery<OneLinkT>(({ pathParams: { linkId } }) => (
  useQuery({
    queryKey: [ONE_LINK_KEY, linkId],
    queryFn: () => instance.get(`${BASE_URL}/${linkId}`),
    enabled: !!linkId,
  })
));

export const useCreateBotsLink = typedMutation<CreateBotsLinkT>(() => {
  const queryCache = useQueryClient();
  const { successToast } = useToastes();
  const push = useTypedHistory()
  return (
    useMutation({
      mutationFn: (data) => instance.post(`${BASE_URL}/bot`, data),
      onSuccess: () => {
        successToast('Bots link was successfully created');
        push(PathsE.LINKS);
        queryCache.invalidateQueries({ queryKey: [LINKS_KEY] });
      }
    })
  )
});

export const useCreateChannelsLink = typedMutation<CreateChannelsLinkT>(() => {
  const queryCache = useQueryClient();
  const { successToast } = useToastes();
  const push = useTypedHistory()
  return (
    useMutation({
        mutationFn: (data) => instance.post(`${BASE_URL}/channel`, data),
        onSuccess: () => {
          successToast('Channels link was successfully created');
          push(PathsE.LINKS);
          queryCache.invalidateQueries({ queryKey: [LINKS_KEY] });
        }
    })
  )
});
