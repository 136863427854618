import { Flex, Box, IconButton } from '@chakra-ui/react';
import MatIcon from 'components/MatIcon';
import { t } from 'i18next';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { ByttonType, TemplateFormValuesT } from 'shared/api/types/channels';
import NumberField from 'shared/components/form/NumberField';
import SelectField from 'shared/components/form/SelectField';
import TextareaField from 'shared/components/form/TextareaField';
import TextField from 'shared/components/form/TextField';
import { HSeparator } from 'shared/components/horizon-ui/separator/Separator';
import { buttonTypeOptions, channelTypeOptions, CreateTemplateFormT } from 'shared/form-values/channels';
import useGetColors from 'shared/hooks/useGetColors';

const TemplateForm = () => {
  const { brand500 } = useGetColors();
  const { fields, append, remove } = useFieldArray<TemplateFormValuesT, 'buttons'>({
    name: "buttons",
  });
  const { watch, setValue } = useFormContext();
  const currentButtonType = watch("currentButtonType") as ByttonType;

  const handleAddButton = (type: ByttonType) => {
    switch (type) {
      case 'inlineKeyboard':
        append({
          type,
          options: { url: '', hide: false, text: '' }
        })
        break;

      case 'keyboard':
        append({
          type,
          options: { hide: false, text: '', resizeKeyboard: true, oneTimeKeyboard: true }
        })
        break;

      default:
        break;
    }
    setValue('currentButtonType', null);
  }

  const fieldProps = {
    mb: '20px',
    w: {
      base: '100%',
    },
  };

  return (
    <>
      <HSeparator my="20px" mx="-20px" width="calc(100% + 40px)" />
      <TextareaField<CreateTemplateFormT>
        name="message"
        label={`${t('channels.table.message')}*`}
        placeholder={t('channels.enterMessage')}
        {...fieldProps}
      />
      <NumberField<CreateTemplateFormT>
        name="afterTime"
        label={`${t('channels.table.afterTime')}*`}
        placeholder={t('channels.enterAfterTime')}
        type="number"
        min={0}
        {...fieldProps}
      />
      <SelectField<CreateTemplateFormT>
        options={channelTypeOptions}
        name="type"
        label={`${t('channels.table.type')}*`}
        placeholder={t('channels.selectType')}
        {...fieldProps}
      />
      {fields.length < 2 && (
        <>
          <HSeparator my="20px" mx="-20px" width="calc(100% + 40px)" />
          <Flex justifyContent="space-between" gap={5} alignItems='end'>
            <SelectField<CreateTemplateFormT>
              options={buttonTypeOptions}
              name="currentButtonType"
              label={t("channels.addNewButton")}
              placeholder={t('channels.selectButtonType')}
            />
            <IconButton
              aria-label="add-icon"
              icon={<MatIcon name="add" size={24} color={brand500} variant="rounded" />}
              onClick={() => handleAddButton(currentButtonType)}
            />
          </Flex>
        </>
      )}
      {fields.map(({ id, type }, index) => (
        <Box key={id}>
          <HSeparator my="20px" mx="-20px" width="calc(100% + 40px)" />
          <TextField
            name={`buttons.${index}.options.text`}
            label={t("channels.buttonText")}
            placeholder={t('channels.enterText')}
            mb="20px"
          />
          {type === 'inlineKeyboard' && (
            <TextField
              name={`buttons.${index}.options.url`}
              label={t("channels.buttonUrl")}
              placeholder={t('channels.enterUrl')}
            />
          )}
          <Flex justifyContent="end">
            <IconButton
              size='lg'
              aria-label="delete-icon"
              icon={<MatIcon name="delete" color="#bb2124" />}
              onClick={() => remove(index)}
            />
          </Flex>
        </Box>
      ))}
    </>
  );
};

export default TemplateForm;
