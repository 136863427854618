import { useMutation, useQuery } from '@tanstack/react-query';
import {
  ActivateT,
  ConfirmT,
  ForgotPasswordT,
  LoginT,
  ResetPasswordT,
  SelfT,
  SignupT
} from 'shared/api/types/auth'
import { ErrorCodes, typedMutation, typedQuery } from "shared/api/types/typedQueries";
import { useAuthContext } from "shared/contexts/AuthContext";
import useToastes from "shared/hooks/useToastes";
import useTypedHistory from "shared/hooks/useTypedHistory";
import { LayoutsE, PathsE } from "shared/routes";
import { AUTHORIZATION_COOKIE } from 'shared/utils/constants';
import { setCookies } from "shared/utils/devUtils";
import { instance } from "./common";

const BASE_URL = 'auth';
export const ME_KEY = 'ME_KEY';

export const useSignup = typedMutation<SignupT>(() => {
  const push = useTypedHistory();
  return useMutation({
    mutationFn: (data) => instance.post(`${BASE_URL}/signup`, data),
    onSuccess: (data) => {
      push(PathsE.LOGIN, LayoutsE.AUTH);
      setCookies(AUTHORIZATION_COOKIE, data.accessToken);
    },
  });
});

export const useLogin = typedMutation<LoginT>(() => {
  const { signIn } = useAuthContext();
  const { errorToast } = useToastes();
  return useMutation({
    mutationFn: (data) => instance.post(`${BASE_URL}/login`, data),
    onSuccess: (data) => {
      setCookies(AUTHORIZATION_COOKIE, data.accessToken);
      signIn(data)
    },
    onError: (data) => errorToast((data.response.data as unknown as { message: string }).message)
  });
});

export const useLogout = typedMutation<FixMeLater>(() => (
  useMutation({
    mutationFn: () => instance.post(`${BASE_URL}/logout`),
  })
));

export const useForgotPassword = typedMutation<ForgotPasswordT>(() => {
  const { successToast } = useToastes();
  return useMutation({
    mutationFn: (data) => instance.post(`${BASE_URL}/reset-password`, data),
    onSuccess: () => {
      successToast('We have successfully sent password recovery instructions to your mailbox')
    }
  });
});

export const useActivateAccount = typedMutation<ActivateT>(({ pathParams: { activationToken } }) => {
  const push = useTypedHistory();
  const { signIn } = useAuthContext();
  return useMutation({
    mutationFn: (data) => instance.post(`${BASE_URL}/activate/${activationToken}`, data),
    onSuccess: (data) => signIn(data),
    onError: (error) => {
      if (error.response.data.error.code === ErrorCodes.EXPIRED) {
        push(PathsE.LINK_EXPIRED, LayoutsE.SYSTEM)
      }
    },
  }
  )
});

export const useSetPassword = typedMutation<ResetPasswordT>(({ pathParams: { resetPasswordToken } }) => {
  const push = useTypedHistory();
  return useMutation({
    mutationFn: (data) => instance.post(`${BASE_URL}/set-password`, { ...data, resetPasswordToken }),
    onSuccess: () => push(PathsE.LOGIN, LayoutsE.AUTH),
    onError: (error) => {
      if (error.response.data.error.code === ErrorCodes.EXPIRED) {
        push(PathsE.LINK_EXPIRED, LayoutsE.SYSTEM)
      }
    },
  }
  )
});

export const useConfirm = typedMutation<ConfirmT>(() => {
  const { signIn } = useAuthContext();
  return useMutation({
    mutationFn: (data) => instance.post(`${BASE_URL}/confirm`, data),
    onSuccess: (data) => signIn(data),
  })
});

export const useGetMe = typedQuery<SelfT>(() => (
  useQuery({
    queryKey: [ME_KEY],
    queryFn: () => instance.get('users/me')
  })
));

// export const useResendOtpCode = typedMutation<ResentOtpCodeT>(() => {
//   const { errorToast } = useToastes();
//   return useMutation({
//     mutationFn: (data) => instance.post(`${BASE_URL}/resend-otp-code`, data),
//     onError: (error) => {
//       if (error.response.data.error.code === ErrorCodes.EXPIRED) {
//         const errorBody = error.response.data.error.data as unknown as { waitingTime: number }
//         errorToast(`You can resend the code via ${formatSecsToMinsAndSecs(errorBody.waitingTime)}`);
//       } else {
//         errorToast(error.response.data.error?.message);
//       }
//     },
//     onSuccess: (data) => {
//       const expirationTime = addExpirationTimeToCurrTime(data.expirationTime);
//       setCookies(OTP_CODE_EXPIRATION_TIME_COOKIE, expirationTime);
//     }
//   })
// });
