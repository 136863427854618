import { Button, Flex, useDisclosure, Text, Box, IconButton, Checkbox, Center } from '@chakra-ui/react'
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import MatIcon from "components/MatIcon";
import { useMemo, useState } from "react";
import { BotAnswerExtendedResponseT, BotAnswerT, TemplateOptionT, UpdateBotAnswerT } from 'shared/api/types/channels'
import Table from "shared/components/table/Table";
import { formatDateToHuman } from "shared/utils/formattings";
import { useTranslation } from "react-i18next";
import { useCreateBotAnswer, useDeleteBotAnswer, useUpdateBotAnswer } from 'shared/api/requests/channels'
import { useParams } from "react-router-dom";
import { PathsParams, PathsE } from "shared/routes";
import { CreateBotAnswerFormT } from 'shared/form-values/channels';
import BotAnswerModal from './BotAnswerModal';
import CutText from '../../components/CutText'
import useGetColors from '../../hooks/useGetColors'
import DeleteModal from '../admin/DeleteModal'

interface Props {
  values: BotAnswerT[]
  templateOptions: TemplateOptionT[]
}

const BotAnswers = ({ values, templateOptions }: Props) => {
  const { t } = useTranslation();
  const { channelId } = useParams<PathsParams[PathsE.SHOW_CHANNEL]>();
  const columnHelper = createColumnHelper<BotAnswerExtendedResponseT>();
  const { brand500 } = useGetColors();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isOpenBotAnswerModal, onOpen: onOpenBotAnswerModal, onClose: onCloseBotAnswerModal } = useDisclosure();
  const [selectedBotAnswer, setSelectedBotAnswer] = useState<string | null>(null);

  const { mutate: createMutation } = useCreateBotAnswer({
    pathParams: { channelId },
    params: undefined
  });

  const { mutate: updateMutation } = useUpdateBotAnswer({
    pathParams: { channelId, templateMatchId: selectedBotAnswer! },
    params: undefined
  });

  const { mutate: deleteMutation } = useDeleteBotAnswer({
    pathParams: { templateMatchId: selectedBotAnswer!, channelId },
    params: undefined
  });

  const columns = useMemo(
    () => [
      columnHelper.accessor('templateId', {
        header: t('channels.table.template'),
        id: 'templateId',
        cell: ({ row }) => (
          <CutText fontWeight="700">
            {row.original.template?.message || t('channels.noMessage')}
          </CutText>
        ),
        meta: {
          isSorting: false,
          styles: {
            sizes: {
              maxW: '90px',
            }
          }
        }
      }),
      columnHelper.accessor('message', {
        header: t('channels.table.message'),
        id: 'message',
      }),
      columnHelper.accessor('type', {
        header: t('channels.table.type'),
        id: 'type',
      }),
      columnHelper.accessor('isActive', {
        header: t('channels.table.isActive'),
        id: 'isActive',
        meta: {
          styles: {
            sizes: {
              width: "70px"
            }
          }
        },
        cell: ({ row }) => {
          const { id, isActive } = row.original;
          const { mutate: updateMutation } = useUpdateBotAnswer({
            pathParams: { channelId, templateMatchId: id },
            params: undefined,
          });

          const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            updateMutation(
              {
                isActive: event.target.checked,
                templateId: row.original.templateId,
                sourceId: row.original.sourceId,
                sourceType: row.original.sourceType,
                type: row.original.type,
                message: row.original.message
              },
            );
          };

          return (
            <Center>
              <Checkbox
                isChecked={isActive}
                onChange={handleCheckboxChange}
                colorScheme="brand"
              />
            </Center>
          );
        },
      }),

      columnHelper.accessor('createdAt', {
        header: t('subscribers.table.createdAt'),
        id: 'createdAt',
        cell: ({ getValue }) => formatDateToHuman(getValue(), false),
        meta: {
          isNumeric: true,
          styles: {
            sizes: {
              width: '120px'
            }
          }
        }
      }),
      columnHelper.accessor('id', {
        header: '',
        id: 'actions',
        cell: ({ getValue }) => (
          <Flex alignItems="center">
            <IconButton aria-label="edit-icon" icon={<MatIcon name="edit" color={brand500} />} onClick={() => {
              setSelectedBotAnswer(getValue());
              onOpenBotAnswerModal();
            }} />
            <IconButton aria-label="delete-icon" icon={<MatIcon name="delete" color="#bb2124" />} onClick={() => {
              setSelectedBotAnswer(getValue());
              onOpen();
            }} />
          </Flex>
        ),
        meta: {
          styles: {
            sizes: {
              width: '100px'
            }
          }
        }
      }),
    ],
    [t]
  );

  const handleCloseBotAnswerModal = () => {
    onCloseBotAnswerModal();
    setSelectedBotAnswer(null)
  }

  return (
    <Box mt={12}>
      <Table data={values} columns={columns as ColumnDef<BotAnswerT>[]} />
      <DeleteModal
        title={t('channels.deleteBotAnswer')}
        text={t('general.approveTo', { phrase: t('channels.deleteBotAnswer') })}
        onClose={onClose}
        isOpen={isOpen}
        onSubmit={() => deleteMutation({}, { onSuccess: () => setSelectedBotAnswer(null) })}
      />
      <BotAnswerModal
        templateOptions={templateOptions}
        title={selectedBotAnswer ? t('channels.updateBotAnswer') : t('channels.botAnswer')}
        onClose={handleCloseBotAnswerModal}
        botAnswer={values?.find(botAnswer => botAnswer.id === selectedBotAnswer)}
        isOpen={isOpenBotAnswerModal}
        buttonText={selectedBotAnswer ? t('buttons.update') : t('buttons.create')}
        onSubmit={(data: CreateBotAnswerFormT) => {
          const requestBody: UpdateBotAnswerT['request'] = {
            ...data,
            sourceType: 'channel',
            sourceId: channelId
          };
          if (!selectedBotAnswer) {
            createMutation(requestBody, { onSuccess: handleCloseBotAnswerModal });
            return;
          }
          updateMutation(requestBody, { onSuccess: handleCloseBotAnswerModal });
        }}
      />
      <Flex justifyContent="end">
        <Button
          mt="16px"
          fontSize="16px"
          color="white"
          backgroundColor="brand.500"
          fontWeight="600"
          _hover={{
            backgroundColor: "brand.400"
          }}
          paddingY="8px"
          display="flex"
          borderRadius="8px"
          alignItems="center"
          onClick={onOpenBotAnswerModal}
        >
          <MatIcon name="add" size={24} color="gray800" variant="rounded" />
          <Text ml="8px">{t('channels.botAnswer')}</Text>
        </Button>
      </Flex>
    </Box>
  )
}

export default BotAnswers;
