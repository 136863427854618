import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { typedInfiniteQuery, typedMutation, typedQuery } from 'shared/api/types/typedQueries';
import {
  BotAnswerRequestT,
  ChannelsT,
  CreateBotAnswerT,
  CreateChannelTemplateT, DeleteBotAnswerT,
  DeleteChannelTemplateT,
  OneChannelT, UpdateBotAnswerT,
  UpdateChannelTemplateT
} from '../types/channels'
import { instance, instanceWithoutTransform } from "./common";
import useToastes from '../../hooks/useToastes';
import { CreateChannelT, UpdateChannelT } from '../types/channels';
import useTypedHistory from '../../hooks/useTypedHistory';
import { LayoutsE, PathsE, PathsParams } from '../../routes';

const BASE_URL = 'channels';

const CHANNELS_KEY = 'CHANNELS_KEY';
const ONE_CHANNEL_KEY = 'ONE_CHANNEL_KEY';
const TEMPLATE_MATCHES_KEY = 'TEMPLATE_MATCHES_KEY';

export const useGetChannels = typedInfiniteQuery<ChannelsT>(({ params }) => (
  useInfiniteQuery({
    queryKey: [CHANNELS_KEY, params],
    queryFn: () => instance.get<ChannelsT['response']>(BASE_URL, { params }),
    initialPageParam: 1,
    getNextPageParam: () => null,
    getPreviousPageParam: () => null,
  })
));

export const useGetOneChannel = typedQuery<OneChannelT>(({ pathParams: { channelId } }) => (
  useQuery({
    queryKey: [ONE_CHANNEL_KEY, channelId],
    queryFn: () => instance.get(`${BASE_URL}/${channelId}`),
    enabled: !!channelId,
  })
));

export const useCreateChannel = typedMutation<CreateChannelT>(() => {
  const queryCache = useQueryClient();
  const { successToast } = useToastes();
  const push = useTypedHistory()
  return (
    useMutation({
      mutationFn: (data) => instance.post(BASE_URL, data),
      onSuccess: () => {
        successToast('Channel was successfully created');
        push(PathsE.CHANNELS);
        queryCache.invalidateQueries({ queryKey: [CHANNELS_KEY] });
      }
    })
  )
});

export const useUpdateOneChannel = typedMutation<UpdateChannelT>(({ pathParams: { channelId } }) => {
  const { successToast } = useToastes();
  const queryCache = useQueryClient()
  const push = useTypedHistory<PathsParams[PathsE.SHOW_CHANNEL]>();
  return (
    useMutation({
      mutationFn: (data) => instance.put(`${BASE_URL}/${channelId}`, data),
      onSuccess: () => {
        successToast('Channel was successfully updated');
        queryCache.invalidateQueries({ queryKey: [CHANNELS_KEY] });
        queryCache.invalidateQueries({ queryKey: [ONE_CHANNEL_KEY, channelId] })
        push(PathsE.SHOW_CHANNEL, LayoutsE.DASHBOARD, { channelId })
      }
    })
  )
});

export const useCreateTemplate = typedMutation<CreateChannelTemplateT>(({ pathParams: { channelId } }) => {
  const queryCache = useQueryClient();
  const { successToast } = useToastes();

  return useMutation({
    mutationFn: (data) => instanceWithoutTransform.post(`${BASE_URL}/${channelId}/templates`, data),
    onSuccess: () => {
      successToast('Template was successfully added');
      queryCache.invalidateQueries({ queryKey: [ONE_CHANNEL_KEY, channelId] });
    },
  });
});

export const useDeleteTemplate = typedMutation<DeleteChannelTemplateT>(({ pathParams: { channelId, templateId } }) => {
  const queryCache = useQueryClient();
  const { successToast } = useToastes();

  return useMutation({
    mutationFn: () => instance.delete(`${BASE_URL}/${channelId}/templates/${templateId}`),
    onSuccess: () => {
      successToast('Template was successfully deleted');
      queryCache.invalidateQueries({ queryKey: [ONE_CHANNEL_KEY, channelId] })
    },
  });
});

export const useUpdateTemplate = typedMutation<UpdateChannelTemplateT>(({ pathParams: { channelId, templateId } }) => {
  const queryCache = useQueryClient();
  const { successToast } = useToastes();

  return useMutation({
    mutationFn: (data) => instanceWithoutTransform.put(`${BASE_URL}/${channelId}/templates/${templateId}`, data),
    onSuccess: () => {
      successToast('Template was successfully updated');
      queryCache.invalidateQueries({ queryKey: [ONE_CHANNEL_KEY, channelId] });
    },
  });
});

export const useGetTemplateMatches = typedQuery<BotAnswerRequestT>(({ pathParams: { channelId } }) => (
  useQuery({
    queryKey: [TEMPLATE_MATCHES_KEY, channelId],
    queryFn: () => instance.get(`template-matches/${channelId}/channel`),
    enabled: !!channelId,
  })
));

export const useCreateBotAnswer = typedMutation<CreateBotAnswerT>(({ pathParams: { channelId } }) => {
  const queryCache = useQueryClient();
  const { successToast } = useToastes();

  return useMutation({
    mutationFn: (data) => {
      return instanceWithoutTransform.post(`template-matches`, data);
    },
    onSuccess: () => {
      successToast('Template match was successfully added');
      queryCache.invalidateQueries({ queryKey: [TEMPLATE_MATCHES_KEY, channelId] });
    },
  });
});

export const useUpdateBotAnswer = typedMutation<UpdateBotAnswerT>(({ pathParams: { channelId, templateMatchId } }) => {
  const queryCache = useQueryClient();
  const { successToast } = useToastes();

  return useMutation({
    mutationFn: (data) => {
      return instanceWithoutTransform.put(`template-matches/${templateMatchId}`, data)
    },
    onSuccess: () => {
      successToast('Template match was successfully updated');
      queryCache.invalidateQueries({ queryKey: [TEMPLATE_MATCHES_KEY, channelId] });
    },
  });
});

export const useDeleteBotAnswer = typedMutation<DeleteBotAnswerT>(({ pathParams: { channelId, templateMatchId } }) => {
  const queryCache = useQueryClient();
  const { successToast } = useToastes();

  return useMutation({
    mutationFn: () => instance.delete(`template-matches/${templateMatchId}`),
    onSuccess: () => {
      successToast('Template match was successfully deleted');
      queryCache.invalidateQueries({ queryKey: [TEMPLATE_MATCHES_KEY, channelId] })
    },
  });
});
