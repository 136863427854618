import { FormControl, FormLabel } from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import { useCallback } from 'react';
import {
  Path,
  PathValue,
  useFormContext
} from 'react-hook-form';
import { SelectFieldProps } from 'shared/types/filters';
import ErrorMessage from './ErrorMessage';
import {chakraStyles} from './AsyncMultiSelectField'

export type OptionT = {
  value: number | string | boolean | null;
  label: string;
};

const SelectField = <F extends UnknownRecord>({
  label,
  placeholder,
  name,
  options,
  size = 'md',
  onChange,
  ...props
}: SelectFieldProps) => {
  const {
    register,
    formState: { errors, touchedFields },
    setValue,
    watch
  } = useFormContext();

  const optionValue = watch(name);

  const findOptionByValue = useCallback(
    (): OptionT | undefined => options.find(({ value }) => optionValue === value) as OptionT,
    [optionValue],
  );

  const isInvalid = !!errors[name] && !!touchedFields[name];

  const handleSelectValue = (option: OptionT) => {
    setValue(name, option.value as PathValue<F, Path<F>>);
  };

  const fieldProps = register(name);

  return (
    <FormControl {...props} size="md" position="relative" isInvalid={isInvalid}>
      {label && (
        <FormLabel fontSize="md" fontWeight="500" mb="8px">
          {label}
        </FormLabel>
      )}
      <Select<OptionT>
        {...fieldProps}
        placeholder={placeholder}
        value={findOptionByValue()}
        useBasicStyles
        className="chakra-select"
        size={size}
        // classNamePrefix={'chakra-select' + `${small ? '--small' : ''}`}
        onChange={(val) => {
          if (onChange) onChange(val.value)
          handleSelectValue(val as OptionT)
        }}
        isInvalid={isInvalid}
        options={options as OptionT[]}
        chakraStyles={chakraStyles}
      />
      <ErrorMessage name={name} />
    </FormControl>
  );
};

export default SelectField;
