import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useGetBots } from "shared/api/requests/bots";
import { useGetChannels } from "shared/api/requests/channels";
import CutText from "shared/components/CutText";
import MainItemMenu from "shared/components/MainItemMenu";
import { PathsE } from "shared/routes";
import { FilterField } from "shared/types/filters";
import { formatDateToHuman } from 'shared/utils/formattings'
import { LinksElementT } from '../../api/types/links';
import MatIcon from '../../../components/MatIcon'
import { Box, Flex, IconButton, Text } from '@chakra-ui/react'
import useHandleCopy from '../../hooks/useHandleCopy'
import useGetColors from '../../hooks/useGetColors'

export const useGetFilterFields = (): FilterField[] => {
  const { t } = useTranslation();

  return useMemo(() => [
    {
      type: 'text',
      name: 'url',
      defaultValue: '',
      placeholder: t('forms.enterUrl'),
      label: t('subscriptions.table.url'),
      size: "lg",
    },
    {
      type: 'text',
      name: 'source',
      defaultValue: '',
      placeholder: t('subscriptions.table.source'),
      label: t('subscriptions.table.source'),
      size: "lg",
    },
    {
      type: 'async-select',
      isMulti: true,
      name: 'channelIds',
      defaultValue: [],
      placeholder: t('forms.selectChannels'),
      optionsQuery: useGetChannels as FixMeLater,
      label: t('fields.channel', { count: 0 }),
      size: "lg",
      labelName: 'name',
      valueName: 'id',
      fullWidth: true
    },
    {
      type: 'async-select',
      isMulti: true,
      name: 'botIds',
      defaultValue: [],
      placeholder: t('forms.selectBots'),
      optionsQuery: useGetBots as FixMeLater,
      label: t('bots.bots'),
      size: "lg",
      labelName: 'name',
      valueName: 'id',
      fullWidth: true
    },
    {
      type: 'date-range',
      name: 'createdAt',
      defaultValue: [],
      label: t('subscribers.table.createdAt'),
      size: "lg"
    },
  ], [t]);
}

export const useGetGetBreadcrumbs = () => {
  const { t } = useTranslation();
  return [
    {
      path: PathsE.HOME,
      text: t('general.home'),
    },
  ];
}

export const useGetColumns = () => {
  const { t } = useTranslation();
  const columnHelper = createColumnHelper<LinksElementT>();
  const handleCopy = useHandleCopy();
  const { gray100, brand500 } = useGetColors();

  const renderCopyButton = (text: string) => (
    <IconButton
      aria-label="copy-text"
      size="xs"
      _hover={{
        backgroundColor: gray100,
      }}
      onClick={() => handleCopy(text)}
    >
      <MatIcon name="content_copy" />
    </IconButton>
  );

  return useMemo(
    () => [
      columnHelper.accessor('id', {
        header: t('subscriptions.table.id'),
        id: 'id',
        cell: ({ getValue }) => (
          <CutText fontWeight="700">
            {getValue()}
          </CutText>
        ),
        meta: {
          isSorting: false,
          styles: {
            sizes: {
              width: '90px',
              maxW: '90px',
            }
          }
        }
      }),
      columnHelper.accessor((row) => row.model.name ?? '', {
        header: t('subscriptions.table.resource'),
        id: 'resource',
        cell: ({ getValue }) => (
          <Flex alignItems="center" justifyContent="space-between" gap={2}>
            <Box mr="4px">
              <MatIcon
                name="group"
                color={brand500}
              />
            </Box>
            <Text as="span" mr="4px">{getValue()}</Text>
          </Flex>
        )
      }),
      columnHelper.accessor('source', {
        header: t('subscriptions.table.source'),
        id: 'source',
      }),
      columnHelper.accessor('buyer', {
        header: t('subscriptions.table.buyer'),
        id: 'buyer',
      }),
      columnHelper.accessor('link', {
        header: t('links.link'),
        id: 'link',
        cell: ({ row, getValue }) => (
          <Box textAlign="center" alignItems="center" display="flex" gap={4}>
            <Box textAlign="center" alignItems="center" display="flex" gap={2}>
              {t('links.link')}
              {renderCopyButton(getValue())}
            </Box>
            |
            <Box textAlign="center" alignItems="center" display="flex" gap={2}>
              {t('links.deepLink')}
              {renderCopyButton(row.original.deepLink)}
            </Box>
          </Box>
        ),
        meta: {
          styles: {
            sizes: {
              width: '240px',
              maxW: '240px',
            }
          }
        }
      }),
      columnHelper.accessor('fbPixel', {
        header: 'FB Pixel/Token',
        id: 'fbPixel',
        cell: ({ getValue }) => {
          const value = getValue();
          if (!value) return null;
          return (
            <Box textAlign="center" alignItems="center" display="flex" gap={2}>
              <CutText>
                {value}
              </CutText>
              {renderCopyButton(value)}
            </Box>
          )
        },
      }),
      columnHelper.accessor('subscribersCount', {
        header: t('subscribers.subscribers'),
        id: 'subscribersCount',
      }),
      columnHelper.accessor('createdAt', {
        header: t('subscriptions.table.createdAt'),
        id: 'createdAt',
        cell: ({ getValue }) => formatDateToHuman(getValue(), false),
        meta: {
          isNumeric: true,
          styles: {
            sizes: {
              width: '120px'
            }
          }
        }
      }),
      columnHelper.accessor('updatedAt', {
        header: t('subscriptions.table.updatedAt'),
        id: 'updatedAt',
        cell: ({ getValue }) => formatDateToHuman(getValue(), false),
        meta: {
          isNumeric: true,
          styles: {
            sizes: {
              width: '120px'
            }
          }
        }
      }),
      columnHelper.accessor('id', {
        header: '',
        id: 'actions',
        cell: ({ row }) => (
          <MainItemMenu
            data={row.original}
            handleActivate={() => { }}
            handleDeactivate={() => { }}
            // handleActivate={handleActivate}
            // handleDeactivate={handleDeactivate}
            // viewLink={PathsE.SHOW_CHANNEL}
            pathsParams={{ subscriptionId: row.original.id }}
          />
        ),
        meta: {
          styles: {
            sizes: {
              width: '44px'
            }
          }
        }
      }),
    ] as ColumnDef<LinksElementT>[],
    [t]
  );
};

