export const LOCALHOST_URL = 'http://localhost:3000';
export const SUPPORT_URL = '#';
export const CONTACT_DEVELOPER_URL = '#';
export const FIVE_MINUTES_MILLISECONDS = 1000 * 60 * 5;
export const THREE_MINUTES_MILLISECONDS = 1000 * 60 * 3;
export const DEFAULT_ERROR_MESSAGE = `We're sorry, something went wrong. If this error persists, please email us at ${CONTACT_DEVELOPER_URL}`;
export const REACT_APP_USER = process.env.REACT_APP_USER ?? '';
export const REACT_APP_USER_NAME = process.env.REACT_APP_USER_NAME ?? '';
export const REACT_APP_USER_SURNAME = process.env.REACT_APP_USER_SURNAME ?? '';
export const REACT_APP_PASSWORD = process.env.REACT_APP_PASSWORD ?? '';
export const IS_LOCAL_ENV = window.location.origin === LOCALHOST_URL;
export const BASE_URL = process.env.REACT_APP_BASE_URL ?? '';
export const BASE_GET_IMAGE_URL = process.env.REACT_APP_BASE_GET_IMAGE_URL ?? '';
export const APP_VERSION = process.env.REACT_APP_VERSION;
export const OTP_CODE_EXPIRATION_TIME_COOKIE = 'otpCodeExpirationTime';
export const SESSION_ID = 'sessionId';
export const AUTHORIZATION_COOKIE = 'authorization';
export const REFRESH_COOKIE = 'refresh';
export const dateFilterKeys = ['createdAt', 'updatedAt'];
export const Locals = {
  EN: 'en',
  RU: 'ru'
};
export const DEFAULT_PAGE_SIZE = 20;
