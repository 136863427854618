import { Skeleton, Tbody, Td, Tr } from '@chakra-ui/react';
import { Table } from '@tanstack/react-table';
import { useMemo } from 'react';
import useGetColors from 'shared/hooks/useGetColors';

interface Props<TData extends UnknownRecord> {
  table: Table<TData>;
  size?: number;
}

const SkeletonTable = <TData extends UnknownRecord>({
  table,
  size = 10,
}: Props<TData>) => {
  const { gray700 } = useGetColors();
  const rows = useMemo(() => Array.from(Array(size).keys()), []);

  return (
    <Tbody>
      {rows.map((row) => (
        <Tr
          key={row}
          color={gray700}
          borderBottom="none"
        >
          {table.getAllColumns().map((cell) => (
            <Td key={cell.id} py="16.5px" px="5px">
              <Skeleton height="15px" />
            </Td>
          ))}
        </Tr>
      ))}
    </Tbody>
  );
};

export default SkeletonTable;
