import { useTranslation } from 'react-i18next';
import { CompleteQueryRequestType } from 'shared/api/types/typedQueries';
import ModelInfo, { InfoBoxesT } from 'shared/components/ModelInfo';
import { NUMERIC_VARIANT } from 'shared/utils/commonStyle';
import { formatDateToHuman } from 'shared/utils/formattings';

type DataQueryT = CompleteQueryRequestType<{
  response: {
    // createdById: string,
    id: string,
    createdAt: string,
    updatedAt: string,
    // isActive: boolean,
    // isDeleted: boolean,
  },
}>;

interface Props<D extends DataQueryT> {
  data: D['response'];
}

const MainModelInfo = <D extends DataQueryT>({ data }: Props<D>) => {
  const { t } = useTranslation();
  // const { isLoading, data: createdByData } = useGetOneSubscriber({
  //   pathParams: { subscriberId: data.createdById },
  //   params: undefined,
  // });

  // if (isLoading) return <Loader />;

  const infoBoxes: InfoBoxesT = [
    {
      label: 'UUID',
      type: 'copy-text',
      value: {
        label: data.id,
        valueToCopy: data.id,
      },
      style: NUMERIC_VARIANT,
    },
    {
      label: t('subscribers.table.createdAt'),
      type: 'text',
      value: formatDateToHuman(data.createdAt),
      style: NUMERIC_VARIANT,
    },
    {
      label: t('subscribers.table.updatedAt'),
      type: 'text',
      value: formatDateToHuman(data.updatedAt),
      style: NUMERIC_VARIANT,
    },
    // {
    //   label: 'Created by',
    //   type: 'link',
    //   link: useGenerateUrl(PathsE.SHOW_SUBSCRIBER, LayoutsE.DASHBOARD, { adminId: data.createdById }),
    //   linkLabel: `${createdByData?.firstName ? `${createdByData.firstName} ` : ''}${createdByData?.middleName ? `${createdByData.middleName} ` : ''}${createdByData?.lastName ? createdByData.lastName : ''}`
    // },
    // {
    //   label: 'Activation status',
    //   type: 'activation-status',
    //   status: data.isActive,
    // },
    // {
    //   label: 'Deletion status',
    //   type: 'deletion-status',
    //   status: data.isDeleted,
    // },
  ]

  return <ModelInfo infoBoxes={infoBoxes} />;
};

export default MainModelInfo;
