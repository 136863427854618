import TextField from 'shared/components/form/TextField';
import { CreateChannelFormT } from '../../form-values/channels';

const ChannelForm = () => {
  const fieldProps = {
    mb: '20px',
    w: {
      base: '100%',
      md: '50%',
    },
  };

  return (
    <>
      <TextField<CreateChannelFormT>
        name="name"
        label="Name *"
        placeholder="Enter name"
        {...fieldProps}
      />
    </>
  );
};

export default ChannelForm;
