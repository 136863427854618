import { t } from 'i18next';
import SelectField from 'shared/components/form/SelectField';
import TextareaField from 'shared/components/form/TextareaField';
import { HSeparator } from 'shared/components/horizon-ui/separator/Separator';
import { botAnswerTypeOptions, CreateBotAnswerFormT } from 'shared/form-values/channels';
import CheckboxField from '../../components/form/CheckboxField';
import { TemplateOptionT } from '../../api/types/channels';

interface BotAnswerFormProps {
  templateOptions: TemplateOptionT[]
}

const BotAnswerForm = ({ templateOptions }: BotAnswerFormProps) => {
  const fieldProps = {
    mb: '20px',
    w: {
      base: '100%',
    },
  };

  const formattedTemplateOptions = templateOptions.map(option => {
    return {
      label: option.message,
      value: option.id
    }
  })

  return (
    <>
      <HSeparator my="20px" mx="-20px" width="calc(100% + 40px)" />
      <SelectField<CreateBotAnswerFormT>
        options={formattedTemplateOptions}
        name="templateId"
        label={`${t('channels.table.template')}*`}
        placeholder={t('channels.table.template')}
        {...fieldProps}
      />
      <SelectField<CreateBotAnswerFormT>
        options={botAnswerTypeOptions}
        name="type"
        label={`${t('channels.table.type')}*`}
        placeholder={t('channels.selectType')}
        {...fieldProps}
      />
      <TextareaField<CreateBotAnswerFormT>
        name="message"
        label={`${t('channels.table.message')}*`}
        placeholder={t('channels.enterMessage')}
        {...fieldProps}
      />
      <CheckboxField
        name="isActive"
        label={`${t('channels.table.isActive')}*`}
      />
    </>
  );
};

export default BotAnswerForm;
